import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { COUNTRIES } from '@utils/Constants';

export default function Special({ player }) {
    if (player.special?.infected) {
        return (
            <Box sx={{ display: 'inline-block', verticalAlign: 'text-top' }}>
                <img src="/images/virus.png"></img>
            </Box>
        );
    } else if (player.special?.patientZero) {
        return (
            <Box sx={{ display: 'inline-block', verticalAlign: 'text-top' }}>
                <img src="/images/coronavirus.png"></img>
            </Box>
        );
    } else if (player.special?.beltHolder) {
        return (
            <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <img height="24px" width="24px" src="/images/belt.png"></img>
            </Box>
        );
    } else if (player.special?.country != null) {
        const image = COUNTRIES[player.special.country].image;

        return (
            <Box sx={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '5px' }}>
                <img height="24px" width="24px" src={image}></img>
            </Box>
        );
    } else if (player.special?.bounty) {
        return (
            <Box sx={{ display: 'inline-block', verticalAlign: 'middle' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 800 }}>
                    <img height="20px" width="20px" style={{ verticalAlign: 'middle' }} src="/images/bounty.png"></img>{' '}
                    {player.special.bounty}
                </Typography>
            </Box>
        );
    }
    return null;
}
