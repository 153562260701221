import { useEffect, useState } from 'react';
import { useFetchWrapper } from '@apis/Utils';
import { Badge } from '@apis/Types';

export function useBadges(): { badges: Badge[] } {
    const [badges, setBadges] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        get('badge').then((data) => {
            setBadges(data);
        });
    }, []);

    return { badges };
}
