import Box from '@mui/material/Box';
import { useArenas } from '@apis/Arenas';
import ArenaCard from '@components/ArenaCard';

const ARENA_COLUMNS = [
    {
        label: 'Average Score',
        property: 'average',
    },
    {
        label: 'High Score',
        property: 'high',
    },
    {
        label: '# Plays',
        property: 'plays',
    },
    {
        label: '% Plays',
        property: 'percentPlays',
    },
];

export default function Arenas() {
    const { arenas } = useArenas();

    return (
        <Box>
            <ArenaCard arenas={arenas} columns={ARENA_COLUMNS} stencils={15} title="Arenas - All Time" />
        </Box>
    );
}
