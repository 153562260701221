import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import CalendarMonth from '@mui/icons-material/CalendarMonth';
import Link from '@mui/material/Link';
import { deepOrange } from '@mui/material/colors';

const ADDRESS = '330 W Davie St, Raleigh, NC 27601';

// TODO Pull from google calendar
const events = [
    {
        title: 'TPL Season 8 : Pincounter 1',
        start: '2024-09-04T23:00:00.000+00:00',
        end: '2024-09-05T01:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Pincounter 2',
        start: '2024-09-11T23:00:00.000+00:00',
        end: '2024-09-12T01:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Pincounter 3',
        start: '2024-10-02T23:00:00.000+00:00',
        end: '2024-10-03T01:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Pincounter 4',
        start: '2024-10-09T23:00:00.000+00:00',
        end: '2024-10-10T01:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Pincounter 5',
        start: '2024-11-06T24:00:00.000+00:00',
        end: '2024-11-07T02:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Pincounter 6',
        start: '2024-11-13T24:00:00.000+00:00',
        end: '2024-11-14T02:30:00.000+00:00',
    },
    {
        title: 'TPL Season 8 : Finale',
        start: '2024-11-20T24:00:00.000+00:00',
        end: '2024-11-21T02:30:00.000+00:00',
    },
];

export default function UpcomingEvents() {
    const filteredEvents = events.filter((e) => new Date(e.start) >= new Date());

    if (!filteredEvents || filteredEvents.length === 0) {
        return (
            <>
                <Typography variant="h6" component="div" align="center">
                    Upcoming Events
                </Typography>
                <Typography align="center" sx={{ padding: '20px' }}>
                    No upcoming events
                </Typography>
            </>
        );
    }

    return (
        <>
            <Typography variant="h6" component="div" align="center">
                Upcoming Events
            </Typography>
            <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                {filteredEvents.map((event) => (
                    <span key={event.title}>
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <Avatar sx={{ backgroundColor: deepOrange[500] }}>
                                    <CalendarMonth />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={event.title}
                                secondary={
                                    <>
                                        <Link
                                            href={`http://www.google.com/calendar/event?action=TEMPLATE&text=${encodeURIComponent(
                                                event.title
                                            )}&dates=${stripDate(event.start)}/${stripDate(event.end)}&details=${
                                                event.title
                                            }&location=${encodeURIComponent(ADDRESS)}&ctz=UTC`}
                                        >
                                            Date - {new Date(event.start).toLocaleString()}
                                        </Link>
                                        <br></br>
                                        <Link href="https://goo.gl/maps/Fs8SMRsv5JR34vCy5">Boxcar Raleigh</Link>
                                    </>
                                }
                            />
                        </ListItem>
                        <Divider variant="inset" component="li" />
                    </span>
                ))}
            </List>
        </>
    );
}

function stripDate(date) {
    return date.replaceAll(/[+-.:]*/g, '');
}
