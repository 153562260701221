import { useContext } from 'react';
import TokenContext from '@components/login/TokenContext';

const API_URL = '/api/v1';

type FunctionWrapper = (method: string, data?: any, headers?: RequestInit['headers']) => Promise<any>;

interface FetchWrapper {
    get: FunctionWrapper;
    put: FunctionWrapper;
    post: FunctionWrapper;
    del: FunctionWrapper;
}

export function useImageUploadDest() {
    const { token } = useContext(TokenContext);
    return {
        url: `${API_URL}/user/picture`,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };
}

export function useFetchWrapper(): FetchWrapper {
    const { token } = useContext(TokenContext);

    function request(method: string) {
        return (endpoint: string, data?: any, headers?: RequestInit['headers']) => {
            return new Promise<any>((resolve, reject) => {
                const baseHeaders = {
                    Authorization: `Bearer ${token}`,
                };

                if (data) {
                    baseHeaders['Content-Type'] = 'application/json';
                }

                fetch(`${API_URL}/${endpoint}`, {
                    method,
                    body: data ? JSON.stringify(data) : null,
                    headers: Object.assign(baseHeaders, headers),
                })
                    .then((response) => {
                        resolveRejectResponse(response, resolve, reject);
                    })
                    .catch((err) => {
                        reject({ error: err.message });
                    });
            });
        };
    }

    return {
        get: request('GET'),
        post: request('POST'),
        put: request('PUT'),
        del: request('DELETE'),
    };
}

function resolveRejectResponse(response, resolve, reject) {
    const contentType = response.headers.get('content-type') || '';

    if (response.status >= 200 && response.status < 300) {
        if (contentType.includes('application/json')) {
            response.json().then(resolve);
        } else {
            resolve();
        }
    } else if (contentType.includes('application/json')) {
        response.json().then(reject);
    } else if (response.status === 401) {
        reject({ error: 'Unauthorized' });
    } else {
        reject({ error: `Server error. Status code: ${response.status}` });
    }
}
