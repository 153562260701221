import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import ArenaLink from '@components/ArenaLink';

import './LatestResults.css';

export default function LatestResults({ className, results }) {
    if (!results) {
        return (
            <>
                <Skeleton variant="text" sx={{ margin: '10px' }} />
                <div className="skeleton-wrapper">
                    <div className="skeleton-col">
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </div>
                    <div className="skeleton-col">
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </div>
                    <div className="skeleton-col">
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                        <Skeleton variant="text" />
                    </div>
                </div>
            </>
        );
    }
    if (results.length === 0) return <Typography align="center">No results</Typography>;

    return (
        <TableContainer component={Paper} className={className}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" className="table-title" component="div">
                Latest Results
            </Typography>
            <Table sx={{ minWidth: 300, tableLayout: 'fixed' }} size="small" aria-label="a dense table">
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell sx={{ width: '25px' }}>#</TableCell>
                        <TableCell>Arena Name</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {results.map((result) => (
                        <TableRow
                            key={result._id}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {result.position + 1}
                            </TableCell>
                            <TableCell component="td" scope="row">
                                <ArenaLink arena={result.arena} />
                            </TableCell>
                            <TableCell component="td" scope="row">
                                {result.score && result.score.toLocaleString()}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
