import React from 'react';

import Box from '@mui/material/Box';

import './Shield.css';

function Shield({ height, width, fill, text }) {
    return (
        <Box sx={{ position: 'relative', paddingLeft: '8px' }}>
            <div className="count-wrapper">{text}</div>

            <Box sx={{ position: 'absolute', top: '0px', zIndex: -1 }}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={width}
                    height={height}
                    fill={fill}
                    version="1"
                    viewBox="0 0 32 32"
                >
                    <path
                        className="shield-path"
                        d="M107 251l-49-16 6-48c6-57 30-102 69-130l27-21 28 21c38 28 62 73 68 130l6 48-43 13c-24 7-48 14-54 16-5 2-32-4-58-13z"
                        transform="matrix(.1 0 0 -.1 0 32)"
                    ></path>
                </svg>
            </Box>
        </Box>
    );
}

export default Shield;
