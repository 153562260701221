import { useState, useContext } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Badge from '@mui/icons-material/Badge';
import Email from '@mui/icons-material/Email';
import Lock from '@mui/icons-material/Lock';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';

import SocialLogins from '@components/login/SocialLogins';
import UserContext from '@components/login/UserContext';
import TokenContext from '@components/login/TokenContext';
import ClaimPlayer from '@components/profile/ClaimPlayer';
import { useUserActions } from '@apis/Users';
import { showSnack } from '@utils/Events';
import { showDialog } from '@utils/Events';

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    name: Yup.string().required('Name is required'),
    email: Yup.string().email().required('Email is required'),
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match")
        .required('Confirmation password is required'),
});

export default function SignupForm({ handleClose }) {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const [serverError, setServerError] = useState(null);
    const { setUser } = useContext(UserContext);
    const { setToken } = useContext(TokenContext);
    const { registerUser } = useUserActions();

    function loggedIn(data) {
        const { user, token } = data;
        if (user) {
            setUser(user);
            setToken(token);
            showSnack('success', `Successfully registered ${data.user.name}`);
            showDialog(<ClaimPlayer />);
            handleClose();
        } else {
            setServerError('An unknown error occured');
        }
    }

    function onSubmit(data) {
        setServerError(null);
        registerUser(data)
            .then(loggedIn)
            .catch((e) => {
                setServerError(e.error || 'An unknown error occured');
            });
    }

    function getError() {
        const keys = Object.keys(errors);
        if (keys && keys.length > 0) {
            return errors[keys[0]]?.message;
        }

        if (serverError) return serverError;
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <>
            <DialogContent sx={{ width: '290px' }}>
                <Stack onKeyPress={handleKeyPress}>
                    {getError() ? (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {getError()}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <FormControl variant="standard">
                        <InputLabel htmlFor="username">Username</InputLabel>
                        <Input
                            id="username"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            {...register('username')}
                            error={!!errors.username}
                            startAdornment={
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="name">Real Name</InputLabel>
                        <Input
                            id="name"
                            {...register('name')}
                            error={!!errors.name}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Badge />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                            id="email"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            {...register('email')}
                            error={!!errors.email}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type="password"
                            {...register('password')}
                            error={!!errors.password}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="confirmPassword">Confirm Password</InputLabel>
                        <Input
                            id="confirmPassword"
                            type="password"
                            {...register('confirmPassword')}
                            error={!!errors.confirmPassword}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Divider sx={{ marginTop: '30px' }}>Or</Divider>
                    <SocialLogins signup handleClose={handleClose}></SocialLogins>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit(onSubmit)}>Create</Button>
            </DialogActions>
        </>
    );
}
