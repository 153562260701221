import React, { useState, useEffect, useRef } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Hidden from '@mui/material/Hidden';

import { useSeason, useSeasonStandings } from '@apis/Seasons';
import StandingsTable from '@components/StandingsTable';
import OverallTable from '@components/OverallTable';
import FinalsBracket from '@components/FinalsBracket';
import ProgressBlob from '@components/ProgressBlob';

import './Standings.css';

function ResultsContainer(props) {
    const { hidden, season, standings, activeHeading } = props;

    const hasFinale = season && season.finaleStandings && season.finaleStandings.length > 0;
    const tournaments = season?.tournaments?.slice().reverse();

    if (hidden) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );
    } else if (!season || !tournaments || tournaments.length === 0) {
        return <Typography align="center">No results</Typography>;
    } else {
        return (
            <Grid container spacing={0}>
                <Hidden smDown mdDown>
                    <Grid item xs={2}>
                        <Box sx={{ position: 'sticky', top: '120px', marginTop: '25px' }}>
                            <List>
                                {hasFinale ? (
                                    <ListItem className={activeHeading === 'finals' ? 'active-item' : 'list-item'}>
                                        <Link style={{ textDecoration: 'none' }} href="#finals">
                                            <ListItemText primary="Finals" className="list-item-text" />
                                        </Link>
                                    </ListItem>
                                ) : (
                                    ''
                                )}
                                <ListItem className={activeHeading === 'overall' ? 'active-item' : 'list-item'}>
                                    <Link style={{ textDecoration: 'none' }} href="#overall">
                                        <ListItemText primary="Overall Standings" className="list-item-text" />
                                    </Link>
                                </ListItem>
                                {tournaments.map((tournament) => (
                                    <ListItem
                                        key={tournament._id}
                                        className={activeHeading === tournament._id ? 'active-item' : 'list-item'}
                                    >
                                        <Link style={{ textDecoration: 'none' }} href={`#${tournament._id}`}>
                                            <ListItemText primary={tournament.name} className="list-item-text" />
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    </Grid>
                </Hidden>
                <Grid item xs={12} md={10} alignItems="center">
                    <Container sx={{ padding: 0 }}>
                        {hasFinale ? (
                            <>
                                <Typography variant="h5" id="finals" align="center" sx={{ scrollMarginTop: 120 }}>
                                    {season.name} - Finals
                                </Typography>
                                <FinalsBracket standings={season.finaleStandings} results={season.finaleResults} />
                            </>
                        ) : (
                            ''
                        )}
                        <Typography variant="h5" id="overall" align="center" sx={{ scrollMarginTop: 120 }}>
                            {season.name} - Overall
                        </Typography>
                        <OverallTable season={season} standings={standings} className="results" />
                        {tournaments.map((row) => (
                            <div className="results" key={row._id}>
                                <Typography
                                    variant="h5"
                                    align="center"
                                    width="100%"
                                    id={row._id}
                                    sx={{ scrollMarginTop: 120, verticalAlign: 'middle', display: 'inline-block' }}
                                >
                                    {row.status !== 'completed' ? <ProgressBlob /> : ''}
                                    <Link
                                        component={RouterLink}
                                        to={`/tournament/${row._id}`}
                                        align="center"
                                        width="100%"
                                    >
                                        {row.name}
                                    </Link>
                                </Typography>
                                <StandingsTable standings={row.standings} />
                            </div>
                        ))}
                    </Container>
                </Grid>
            </Grid>
        );
    }
}

export default function Standings() {
    const [loading, setLoading] = useState(true);
    const [activeId, setActiveId] = useState('overall');

    const { id } = useParams();
    const { season } = useSeason(id, true);
    const { standings } = useSeasonStandings(id);

    const headingElementsRef = useRef({});

    function observeHeadings(observer) {
        const headingElements = Array.from(document.querySelectorAll('h5'));
        headingElements.forEach((element) => observer.observe(element));
    }

    useEffect(() => {
        const observer = new IntersectionObserver(headerObserve.bind(this, headingElementsRef, setActiveId), {
            rootMargin: '-110px 0px -40% 0px',
        });

        if (season && standings) {
            setLoading(false);
            observeHeadings(observer);
        }

        return () => observer.disconnect();
    }, [season, standings]);

    return (
        <div>
            <ResultsContainer hidden={loading} season={season} standings={standings} activeHeading={activeId} />
        </div>
    );
}

function headerObserve(headingElementsRef, setActiveId, headings) {
    headingElementsRef.current = headings.reduce((map, headingElement) => {
        map[headingElement.target.id] = headingElement;
        return map;
    }, headingElementsRef.current);

    const visibleHeadings = [];
    Object.keys(headingElementsRef.current).forEach((key) => {
        const headingElement = headingElementsRef.current[key];
        if (headingElement.isIntersecting) visibleHeadings.push(headingElement);
    });

    const headingElements = Array.from(document.querySelectorAll('h5'));
    const getIndexFromId = (id) => headingElements.findIndex((heading) => heading.id === id);

    if (visibleHeadings.length === 1) {
        setActiveId(visibleHeadings[0].target.id);
    } else if (visibleHeadings.length > 1) {
        const sortedVisibleHeadings = visibleHeadings.sort(
            (a, b) => getIndexFromId(b.target.id) - getIndexFromId(a.target.id)
        );
        setActiveId(sortedVisibleHeadings[0].target.id);
    }
}
