import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { DataGrid } from '@mui/x-data-grid';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { showSnack } from '@utils/Events';
import TablePlugins from './ManageUsersTablePlugins';

const columns = [
    {
        field: 'name',
        headerName: 'Name',
        width: 150,
        editable: true,
    },
    {
        field: 'ifpaId',
        headerName: 'IFPA',
        width: 150,
        editable: true,
    },
    {
        field: 'matchplayId',
        headerName: 'Matchplay',
        width: 110,
        editable: true,
    },
    {
        field: 'claimedBy',
        headerName: 'Claimed By',
        valueGetter: (params) => params.value?.name,
        editable: false,
        width: 160,
    },
    {
        field: 'pendingClaim',
        headerName: 'Pending Claim',
        renderCell: (params) => {
            if (params.value) return <Chip label="Pending" color="warning" />;
        },
    },
];

export default function ManageUsersTable({ plugin, players, error, selectionModel, setSelectionModel }) {
    if (error) showSnack('error', error.error);

    if (!players && !error)
        return (
            <Box sx={{ display: 'flex', marginTop: '50px' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );

    if (error || players.length === 0) return <Typography align="center">No results</Typography>;

    const gridColumns = TablePlugins[plugin] ? columns.concat(TablePlugins[plugin]) : columns;

    return (
        <DataGrid
            rows={players}
            columns={gridColumns}
            checkboxSelection
            disableSelectionOnClick
            getRowId={(r) => r._id}
            experimentalFeatures={{ newEditingApi: true }}
            initialState={{
                sorting: {
                    sortModel: [{ field: 'pendingClaim', sort: 'desc' }],
                },
            }}
            selectionModel={selectionModel}
            onSelectionModelChange={setSelectionModel}
        />
    );
}
