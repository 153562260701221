import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import SignupForm from '@components/login/SignupForm';
import LoginForm from '@components/login/LoginForm';
import ForgotLoginForm from '@components/login/ForgotLoginForm';

import './LoginDialog.css';

export default function LoginDialog({ open, handleClose }) {
    const [form, setForm] = useState(null);

    function handleChangeForm(newForm) {
        setForm(newForm);
    }

    function handleCancel() {
        handleClose();
        setTimeout(() => {
            setForm(null);
        }, 500);
    }

    return (
        <Dialog open={open} onClose={handleCancel}>
            <DialogTitle align="center" sx={{ backgroundColor: 'primary.main', color: 'white', marginBottom: '20px' }}>
                {getTitle(form)}
            </DialogTitle>
            {getFormContents(form, handleCancel, handleChangeForm)}
        </Dialog>
    );
}

function getTitle(form) {
    if (form === 'signup') return 'Register';
    else if (form === 'forgot') return 'Forgot Login';
    return 'Login';
}

function getFormContents(form, handleCancel, handleChangeForm) {
    if (form === 'signup') return <SignupForm handleClose={handleCancel} />;
    else if (form === 'forgot') return <ForgotLoginForm handleClose={handleCancel} />;
    return <LoginForm handleClose={handleCancel} changeForm={handleChangeForm} />;
}
