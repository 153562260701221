import { useEffect, useState, useCallback } from 'react';
import { Player, PlayerProfile } from '@apis/Types';
import { useFetchWrapper } from '@apis/Utils';

export function usePlayer(id: string): { player: Player } {
    const [player, setPlayer] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`player/${id}`).then((data) => {
            setPlayer(data);
        });
    }, [id]);

    return { player };
}

export function usePlayers(unclaimed = false): { players: Player[] } {
    const [players, setPlayers] = useState(null);
    const { get } = useFetchWrapper();

    const queryString = unclaimed ? '?unclaimed=true' : '';

    useEffect(() => {
        get(`player${queryString}`).then((data) => {
            setPlayers(data);
        });
    }, [queryString]);

    return { players };
}

export function usePlayersWithUsers(): { players: Player[]; refreshPlayers: () => void; error: { error: string } } {
    const [players, setPlayers] = useState(null);
    const [error, setError] = useState(null);
    const { get } = useFetchWrapper();

    const loadData = useCallback(() => {
        get(`player/users`)
            .then((data) => {
                setPlayers(data);
            })
            .catch((err) => {
                setError(err);
            });
    }, []);

    useEffect(loadData, [loadData]);

    return { players, refreshPlayers: loadData, error };
}

export function useProfile(id: string): Partial<PlayerProfile> {
    const [profile, setProfile] = useState({});
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`player/${id}/profile`).then((data) => {
            setProfile(data);
        });
    }, [id]);

    return profile;
}

export function usePlayerActions() {
    const { put, post } = useFetchWrapper();
    function updatePlayer(id: string, data: Partial<Player>): Promise<void> {
        return put(`player/${id}`, data);
    }

    function updatePlayers(data: Partial<Player>[]): Promise<void> {
        return put(`player/batch`, data);
    }

    function approveClaim(id: string): Promise<void> {
        return post(`player/${id}/approve`);
    }

    function denyClaim(id: string): Promise<void> {
        return post(`player/${id}/deny`);
    }

    function claimPlayer(id: string) {
        return post(`player/${id}/claim`, null);
    }

    return { updatePlayer, updatePlayers, approveClaim, denyClaim, claimPlayer };
}
