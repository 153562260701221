import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Popover from '@mui/material/Popover';
import { deepOrange, green } from '@mui/material/colors';

import BadgeCard from '@components/BadgeCard';
import Shield from '@components/Shield';

export default function PlayerAvatar(props) {
    const { player, hideBadgeCount, variant, sx, className } = props;
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        if (player.badges.length > 0 && !hideBadgeCount) {
            setAnchorEl(event.currentTarget);
        }
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <span className={className}>
            <Badge
                onClick={handleClick}
                className="badge"
                overlap="circular"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                badgeContent={
                    !hideBadgeCount &&
                    player.badges &&
                    player.badges.length > 0 && (
                        <Shield height={40} width={40} fill={green[500]} text={player.badges.length} />
                    )
                }
            >
                {player.picture ? (
                    <Avatar sx={sx} src={player.picture} variant={variant} />
                ) : (
                    <Avatar sx={{ bgcolor: deepOrange[500], ...sx }} variant={variant}>
                        {player.name[0]}
                    </Avatar>
                )}
            </Badge>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <BadgeCard playerId={player._id} />
            </Popover>
        </span>
    );
}
