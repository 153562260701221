import { useEffect, useState } from 'react';
import { Tournament } from '@apis/Types';
import { useFetchWrapper } from '@apis/Utils';

export function useCurrentTournaments(fields: string[]): { tournaments: Tournament[] } {
    const queryString = fields ? `&fields=${fields.join(',')}` : '';
    const [tournaments, setTournaments] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`tournament?season=current${queryString}`).then((data) => {
            setTournaments(data);
        });
    }, []);

    return { tournaments };
}

export function useTournament(id: string): { tournament: Tournament } {
    const [tournament, setTournament] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`tournament/${id}`).then((data) => {
            setTournament(data);
        });
    }, [id]);

    return { tournament };
}

export function useInProgress(): { id: string } {
    const [id, setId] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`tournament/live`).then((data) => {
            setId(data._id);
        });
    }, []);

    return { id };
}
