export default {
    Virus: [
        {
            label: 'Infected',
            path: 'infected',
            icon: <img width="32px" height="27px" src="/images/Biohazard.png" />,
        },
    ],
    Smackdown: [
        {
            label: 'Smackdown',
            path: 'smackdown',
            icon: <img width="32px" height="27px" src="/images/belt.png" />,
        },
    ],
    Olympics: [
        {
            label: 'Olympics',
            path: 'olympics',
            icon: <img width="32px" height="27px" src="/images/olympic-medal.png" />,
        },
    ],
    Bounty: [
        {
            label: 'Bounty',
            path: 'bounty',
            icon: <img width="24px" height="24px" src="/images/bounty.png" />,
        },
    ],
};
