import { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar, { AppBarProps } from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';

import MenuItem from '@mui/material/MenuItem';
import GavelIcon from '@mui/icons-material/Gavel';
import HomeIcon from '@mui/icons-material/Home';
import TocIcon from '@mui/icons-material/Toc';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import StadiumIcon from '@mui/icons-material/Stadium';
import ShieldIcon from '@mui/icons-material/Shield';
import LabelIcon from '@mui/icons-material/Label';
import HistoryIcon from '@mui/icons-material/History';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ApprovalIcon from '@mui/icons-material/Approval';

import LoginDialog from '@components/login/LoginDialog';
import UserContext from '@components/login/UserContext';
import TokenContext from '@components/login/TokenContext';
import NotificationBell from '@components/navbar/NotificationBell';
import MobileMenu from '@components/navbar/MobileMenu';
import DesktopMenu from '@components/navbar/DesktopMenu';
import LoginAvatar from '@components/navbar/LoginAvatar';
import NavbarPlugins from '@components/navbar/NavbarPlugins';
import Logo from '@components/Logo';
import { usePastSeasons, useSeason } from '@apis/Seasons';
import { useCurrentTournaments } from '@apis/Tournaments';
import { Player } from '@apis/Types';

import './Navbar.css';

const pincountersMenu = {
    label: 'Pincounters',
    icon: <EmojiEventsIcon />,
    subpages: [],
};

const pastSeasonsMenu = {
    label: 'Past Seasons',
    icon: <HistoryIcon />,
    subpages: [],
};

const defaultPages = [
    {
        label: 'Home',
        path: '/',
        icon: <HomeIcon />,
    },
    {
        label: 'Standings',
        path: 'season/current',
        icon: <TocIcon />,
    },
    pincountersMenu,
    {
        label: 'Arenas',
        path: 'arenas',
        icon: <StadiumIcon />,
    },
    {
        label: 'Badges',
        path: 'badges',
        icon: <ShieldIcon />,
    },
    pastSeasonsMenu,
    {
        label: 'Rules',
        path: '/rules',
        icon: <GavelIcon />,
    },
];

const adminPages = [
    {
        label: 'Admin',
        icon: <AdminPanelSettingsIcon />,
        subpages: [
            {
                label: 'Import',
                path: '/import',
                icon: <ImportExportIcon />,
            },
            {
                label: 'Manage Users',
                path: '/manageusers',
                icon: <ApprovalIcon />,
            },
        ],
    },
];

interface NavbarProps {
    position: AppBarProps['position'];
}

export default function ResponsiveNavBar(props: NavbarProps) {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const [loginOpen, setLoginOpen] = useState(false);
    const [pincounters, setPincounters] = useState([]);
    const [pastSeasons, setPastSeasons] = useState([]);

    const { user, setUser } = useContext(UserContext);
    const { setToken } = useContext(TokenContext);
    const { season } = useSeason('current');

    const navigate = useNavigate();

    function handleOpenMobileNav() {
        setMobileNavOpen(true);
    }

    function handleCloseMobileNav() {
        setMobileNavOpen(false);
    }

    function handleOpenUserMenu(event) {
        setAnchorElUser(event.currentTarget);
    }

    function handleCloseUserMenu() {
        setAnchorElUser(null);
    }

    function handleNavigate(page) {
        navigate(page);
        handleCloseMobileNav();
    }

    function handleCloseLoginDialog() {
        setLoginOpen(false);
    }

    // Menu click handlers
    function handleClickMyPlayer() {
        const player = user.player as Player;
        if (user.player) {
            navigate(`player/${player._id}`);
        } else {
            navigate(`claimplayer`);
        }
        handleCloseUserMenu();
    }

    function handleClickLogin() {
        setLoginOpen(true);
    }

    function handleClickLogout() {
        setUser(null);
        setToken(null);
        handleCloseUserMenu();
    }

    // Hook up menu to state, to make it reactive
    pincountersMenu.subpages = pincounters;
    pastSeasonsMenu.subpages = pastSeasons;

    const { seasons } = usePastSeasons();
    const { tournaments } = useCurrentTournaments(['_id']);

    useEffect(() => {
        const subpages = seasons?.map((d) => ({
            label: d.name,
            path: `season/${d._id}`,
            icon: <LabelIcon />,
        }));
        setPastSeasons(subpages);
    }, [seasons]);

    useEffect(() => {
        const subpages = tournaments?.map((d, index) => ({
            label: `Pincounter #${index + 1}`,
            path: `tournament/${d._id}`,
            icon: <LabelIcon />,
        }));

        setPincounters(subpages);
    }, [tournaments]);

    // Inject plugin based menu items
    const pages = NavbarPlugins[season?.plugin] ? defaultPages.concat(NavbarPlugins[season?.plugin]) : defaultPages;

    return (
        <AppBar position={props.position} className="Navbar">
            <Container maxWidth={false}>
                <Toolbar disableGutters>
                    <Box
                        sx={{
                            display: { xs: 'flex', md: 'none' },
                        }}
                    >
                        <IconButton
                            size="large"
                            aria-label="open navigation"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenMobileNav}
                            color="inherit"
                        >
                            <MenuIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <MobileMenu
                            pages={pages}
                            adminPages={adminPages}
                            open={mobileNavOpen}
                            onClose={handleCloseMobileNav}
                            onClick={handleNavigate}
                        />
                    </Box>
                    <Box flexGrow={{ xs: 1, md: 0 }} display="flex" alignItems="center">
                        <Logo height="40px" width="140px" fill="#FFFFFF" className="logo" />
                    </Box>
                    <Box
                        sx={{
                            marginLeft: '24px',
                            flexGrow: 1,
                            display: { xs: 'none', sm: 'none', md: 'inline-block' },
                        }}
                    >
                        <DesktopMenu pages={pages} adminPages={adminPages} onClick={handleNavigate} />
                    </Box>
                    <Box sx={{ flexGrow: 0 }}>
                        <NotificationBell user={user} sx={{ marginRight: '15px' }} />
                        <LoginAvatar
                            user={user}
                            handleClickLogin={handleClickLogin}
                            handleOpenUserMenu={handleOpenUserMenu}
                        />
                        <Menu
                            sx={{ mt: '45px' }}
                            id="menu-appbar"
                            anchorEl={anchorElUser}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            open={Boolean(anchorElUser)}
                            transitionDuration={0}
                            onClose={handleCloseUserMenu}
                        >
                            <Box sx={{ width: '150px' }}>
                                <MenuItem onClick={handleClickMyPlayer}>
                                    <Typography textAlign="center">My Player</Typography>
                                </MenuItem>
                                <MenuItem onClick={handleClickLogout}>
                                    <Typography textAlign="center">Logout</Typography>
                                </MenuItem>
                            </Box>
                        </Menu>
                    </Box>
                </Toolbar>
            </Container>
            <LoginDialog open={loginOpen} handleClose={handleCloseLoginDialog} />
        </AppBar>
    );
}
