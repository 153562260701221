import React from 'react';
import { deepOrange } from '@mui/material/colors';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';

import './FinalsGame.css';

export default function FinalsGame({ game, count }) {
    const theme = useTheme();

    const [firstResult, secondResult] = game;
    const firstWon = firstResult.score > secondResult.score;
    const firstColor = firstWon ? deepOrange[400] : '#787a80';
    const secondColor = firstWon ? '#787a80' : deepOrange[400];

    // If result is last round and the position isnt someone who finished first or second
    const consolation = firstResult.round === count - 1 && firstResult.player.position > 2;

    return (
        <Box className={consolation ? 'consolation' : ''}>
            <svg width="200" height="80" x="44" y="100" viewBox="0 0 200 82">
                {consolation ? (
                    <text x="100" y="8" fill="#999" fontSize="12" fontWeight="800" textAnchor="middle">
                        CONSOLATION
                    </text>
                ) : (
                    ''
                )}
                <rect width="200" height="45" y="12" fill="#58595e" rx="3" ry="3"></rect>
                <rect
                    width="200"
                    height="22.5"
                    y="12"
                    fill={theme.palette.primary.main}
                    rx="3"
                    ry="3"
                    className="player-rect"
                ></rect>
                <rect
                    width="200"
                    height="22.5"
                    y="34.5"
                    fill={theme.palette.primary.main}
                    rx="3"
                    ry="3"
                    className="player-rect"
                ></rect>
                <rect width="30" height="45" x="170" y="12" fill={secondColor} rx="3" ry="3"></rect>
                <rect width="30" height="22.5" x="170" y="12" fill={firstColor} rx="3" ry="3"></rect>
                <path fill="none" d="M0 12H200V34.5H0z"></path>
                <defs>
                    <clipPath id="b220c77e-3538-485e-b040-4c0939248804">
                        <path d="M0 12H165V34.5H0z"></path>
                    </clipPath>
                </defs>
                <g clipPath="url(#b220c77e-3538-485e-b040-4c0939248804)">
                    <text x="5" y="28" style={{ WebkitTextShadow: NaN, textShadow: NaN }} fill="#FFF" fontSize="12">
                        {`${firstResult.player.seed} ${firstResult.player.name}`}
                    </text>
                </g>
                <text x="185" y="28" fill="#23252D" fontSize="12" textAnchor="middle">
                    {firstResult.score}
                </text>
                <g>
                    <path fill="none" d="M0 34.5H200V57H0z"></path>
                    <defs>
                        <clipPath id="bfd18d86-bed2-40f8-84f8-e8dae9fbdc61">
                            <path d="M0 34.5H165V57H0z"></path>
                        </clipPath>
                    </defs>
                    <g clipPath="url(#bfd18d86-bed2-40f8-84f8-e8dae9fbdc61)">
                        <text
                            x="5"
                            y="50.5"
                            style={{ WebkitTextShadow: NaN, textShadow: NaN }}
                            fill="#FFF"
                            fontSize="12"
                        >
                            {`${secondResult.player.seed} ${secondResult.player.name}`}
                        </text>
                    </g>
                    <text x="185" y="50.5" fill="#23252D" fontSize="12" textAnchor="middle">
                        {secondResult.score}
                    </text>
                </g>
                <path stroke="#444549" d="M0 34.5L200 34.5"></path>
            </svg>
        </Box>
    );
}
