// React
import React, { useState, useEffect } from 'react';
import Container from '@mui/material/Container';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { PullToRefresh } from 'react-js-pull-to-refresh';
import { PullDownContent, ReleaseContent, RefreshContent } from 'react-js-pull-to-refresh';

// Components
import Footer from '@components/Footer';
import CustomDialog from '@components/CustomDialog';
import ScrollToTop from '@components/ScrollToTop';
import UserContext from '@components/login/UserContext';
import TokenContext from '@components/login/TokenContext';
import ClaimPlayer from '@components/profile/ClaimPlayer';
import Navbar from '@components/navbar/Navbar';
import { useInProgress } from '@apis/Tournaments';
import { useUserActions } from '@apis/Users';

// Pages
import Home from '@pages/Home';
import Standings from '@pages/Standings';
import Rules from '@pages/Rules';
import BadgePage from '@pages/BadgePage';
import Arenas from '@pages/Arenas';
import Tournament from '@pages/Tournament';
import Attributions from '@pages/Attributions';
import PlayerProfile from '@pages/PlayerProfile';
import ArenaStats from '@pages/ArenaStats';
import AllImports from '@pages/admin/AllImports';
import ManageUsers from '@pages/admin/ManageUsers';
import Import from '@pages/admin/Import';
import PasswordReset from '@pages/PasswordReset';
import InfectedLeaderboard from '@pages/InfectedLeaderboard';
import SmackdownLeaderboard from '@pages/SmackdownLeaderboard';
import OlympicLeaderboard from '@pages/OlympicLeaderboard';
import BountyLeaderboard from '@pages/BountyLeaderboard';

// Styles
import './App.css';

export default function App() {
    // Palette
    const theme = createTheme({
        palette: {
            primary: {
                main: '#1f293f',
            },
            secondary: {
                main: '#393E46',
            },
        },
        components: {
            MuiTooltip: {
                styleOverrides: {
                    tooltip: {
                        fontSize: '.875rem',
                    },
                },
            },
            MuiLink: {
                styleOverrides: {
                    root: {
                        color: 'black',
                    },
                },
            },
        },
    });

    let token = localStorage.getItem('token');
    token = token && JSON.parse(token);

    const [user, setUser] = useState(null);
    const [tokenState, setTokenState] = useState(token);
    const [snack, setSnack] = useState(null);
    const [snackOpen, setSnackOpen] = useState(false);
    const { id } = useInProgress();
    const { whoami } = useUserActions();

    // Query the server for the currently logged in user
    useEffect(() => {
        if (token) {
            whoami(token).then((user) => {
                if (user) {
                    setUser(user);
                }
            });
        }

        document.addEventListener('showsnack', showSnack);

        return () => {
            removeEventListener('showsnack', showSnack);
        };
    }, []);

    function showSnack({ detail }: KeyboardEvent) {
        setSnackOpen(true);
        setSnack(detail);
    }

    function setToken(token: string) {
        if (token) {
            localStorage.setItem('token', JSON.stringify(token));
        } else {
            localStorage.removeItem('token');
        }
        setTokenState(token);
    }

    const userProvider = {
        user,
        setUser,
    };

    const tokenProvider = {
        token: tokenState,
        setToken,
    };

    function handleSnackbarClose(event: React.SyntheticEvent, reason?: string) {
        if (reason === 'clickaway') return;

        setSnackOpen(false);
    }

    function onRefresh(): Promise<void> {
        return new Promise(() => location.reload());
    }

    return (
        <ThemeProvider theme={theme}>
            <PullToRefresh
                pullDownContent={<PullDownContent />}
                releaseContent={<ReleaseContent />}
                refreshContent={<RefreshContent />}
                pullDownThreshold={200}
                onRefresh={onRefresh}
                triggerHeight={150}
                backgroundColor="white"
                startInvisible={true}
            >
                <Router>
                    <ScrollToTop />
                    <TokenContext.Provider value={tokenProvider}>
                        <UserContext.Provider value={userProvider}>
                            {id ? (
                                <Alert severity="warning" sx={{ justifyContent: 'center' }}>
                                    Pincounter in progress!
                                    <Link href={`/tournament/${id}`}> Click here to follow along</Link>
                                </Alert>
                            ) : null}
                            <Navbar position="sticky" />
                            <Container maxWidth="xl" className="main-container">
                                <Routes>
                                    <Route path="/" element={<Home />} />
                                    <Route path="/rules" element={<Rules />} />
                                    <Route path="/season/:id" element={<Standings />} />
                                    <Route path="/badges" element={<BadgePage />} />
                                    <Route path="/arenas" element={<Arenas />} />
                                    <Route path="/tournament/:id" element={<Tournament />} />
                                    <Route path="/import/:id" element={<Import />} />
                                    <Route path="/attributions" element={<Attributions />} />
                                    <Route path="/player/:id" element={<PlayerProfile />} />
                                    <Route path="/arena/:id" element={<ArenaStats />} />
                                    <Route path="/import" element={<AllImports />} />
                                    <Route path="/manageusers" element={<ManageUsers />} />
                                    <Route path="/claimplayer" element={<ClaimPlayer fullPage />} />
                                    <Route path="/reset/:token" element={<PasswordReset />} />
                                    <Route path="/infected" element={<InfectedLeaderboard />} />
                                    <Route path="/smackdown" element={<SmackdownLeaderboard />} />
                                    <Route path="/olympics" element={<OlympicLeaderboard />} />
                                    <Route path="/bounty" element={<BountyLeaderboard />} />
                                </Routes>
                            </Container>
                            <Footer />
                            <Snackbar
                                open={!!snackOpen}
                                autoHideDuration={6000}
                                onClose={handleSnackbarClose}
                                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                            >
                                <Alert onClose={handleSnackbarClose} severity={snack?.severity} sx={{ width: '100%' }}>
                                    {snack?.message}
                                </Alert>
                            </Snackbar>
                            <CustomDialog />
                        </UserContext.Provider>
                    </TokenContext.Provider>
                </Router>
            </PullToRefresh>
        </ThemeProvider>
    );
}
