import { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';

import { useAllImportStatuses, useImportActions } from '@apis/Imports';
import { showSnack } from '@utils/Events';
import ProgressBlob from '@components/ProgressBlob';

const validationSchema = Yup.object().shape({
    matchplay: Yup.string().required('Matchplay ID is required'),
});

export default function AllImports() {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const [loading, setLoading] = useState(true);
    const { importStatuses, refresh, error } = useAllImportStatuses();
    const { createImport } = useImportActions();

    useEffect(() => {
        if (importStatuses) {
            setLoading(false);
        }
    }, [importStatuses]);

    useEffect(() => {
        if (error) {
            showSnack('error', error.error);
            setLoading(false);
        }
    }, [error]);

    function handleKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit)();
            e.preventDefault();
        }
    }

    function onSubmit(data) {
        const { matchplay, finale } = data;
        setLoading(true);
        createImport('current', matchplay, finale)
            .then(() => {
                showSnack('success', 'Import successfully queued');
                refresh();
            })
            .catch((response) => {
                if (response.error) {
                    showSnack('error', response.error);
                } else {
                    showSnack('error', 'An unknown error occurred');
                }

                setLoading(false);
            });
    }

    return (
        <Container>
            <form>
                <Stack alignItems="center">
                    <Box>
                        <TextField
                            required
                            id="matchplay"
                            label="Matchplay ID"
                            variant="outlined"
                            sx={{ width: '190px' }}
                            {...register('matchplay')}
                            error={errors.matchplay ? true : false}
                            helperText={errors.matchplay?.message}
                            onKeyPress={handleKeyPress}
                        />
                        <FormControlLabel
                            sx={{ width: 'fit-content' }}
                            control={<Switch {...register('finale')} color="secondary" />}
                            label="Finale"
                            labelPlacement="top"
                        />
                    </Box>
                    <Button
                        color="secondary"
                        variant="contained"
                        size="large"
                        sx={{ margin: '20px auto', width: '300px' }}
                        onClick={handleSubmit(onSubmit)}
                    >
                        Import
                    </Button>
                </Stack>
            </form>
            {loading ? (
                <Box sx={{ display: 'flex', marginTop: '50px' }} alignItems="center">
                    <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
                </Box>
            ) : null}
            {importStatuses && importStatuses.length > 0 ? <ImportsTable imports={importStatuses} /> : null}
        </Container>
    );
}

function ImportsTable({ imports }) {
    return (
        <>
            <Typography variant="h6">Previous Imports</Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Job Id</TableCell>
                            <TableCell>Tournament Name</TableCell>
                            <TableCell>Created</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {imports.map((i) => (
                            <TableRow key={i.jobId} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                <TableCell component="th" scope="row">
                                    <ProgressBlob status={i.jobStatus} />
                                    <Link component={RouterLink} to={`/import/${i.jobId}`}>
                                        {i.jobId}
                                    </Link>
                                </TableCell>
                                <TableCell>{i.importStatus?.tournamentName}</TableCell>
                                <TableCell>{new Date(parseInt(i.created)).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
}
