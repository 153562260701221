import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import './Rules.css';

const Accordion = styled((props) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255, 255, 255, .05)' : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function Rules() {
    const [unexpanded, setUnexpanded] = React.useState([]);

    const handleChange = (panel) => () => {
        const index = unexpanded.indexOf(panel);

        const newUnexpanded = [].concat(unexpanded);

        if (index > -1) {
            newUnexpanded.splice(index, 1);
        } else {
            newUnexpanded.push(panel);
        }

        setUnexpanded(newUnexpanded);
    };
    return (
        <div>
            <Typography variant="h4" noWrap component="div" sx={{ marginBottom: '16px', marginLeft: '16px' }}>
                Triangle Pinball League Format and Rules
            </Typography>

            <Accordion expanded={!unexpanded.includes('introduction')} onChange={handleChange('introduction')}>
                <AccordionSummary aria-controls="introduction" id="introduction">
                    <Typography variant="h5">1. Introduction</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The Triangle Pinball League (TPL) rules are based on the Free State Pinball Association (FSPA)
                        league rules and the Buffalo Pinball League (BPL) rules, with modifications for our specific
                        format/location. The Triangle Pinball League rules provide a format for running a friendly
                        league competition for pinball players of all skill levels. These rules are designed for leagues
                        with six or more players, playing on four or more machines at a single location. Scoring is
                        based on how well one does relative to other players. The nature of the TPL system allows
                        players of all skill levels to play in a single league which is fun and competitive for
                        everyone.
                        <Typography variant="h6" className="subheading">
                            1.1 League Officials
                        </Typography>
                        In these rules, <i>SLO</i> stands for Senior League Official. For situations requiring an
                        immediate decision or rule interpretation, this refers to the highest-ranking league official
                        present who is not directly affected by the decision. In particular, rulings of malfunctions or
                        interference should be deferred to an uninvolved official. In other cases, it refers to any
                        appropriate league officer.
                        <Typography variant="h6" className="subheading">
                            1.2 Discretion of League Officials
                        </Typography>
                        These rules are a guide. At times situations will arise that aren&apos;t specifically covered by
                        these rules. In these cases, the SLO should make a decision in the spirit of the rules. This
                        decision shall be documented for later reference, and be applied consistently should the
                        situation arise again.
                        <p>
                            Additionally, the SLO of a league may choose to override specific rules in this guide if
                            he/she deems that such modifications are beneficial for that particular league. Any such
                            modifications shall be made prior to the start of the season, and announced to players at
                            the league location and on the TPL website.
                        </p>
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('attendance')} onChange={handleChange('attendance')}>
                <AccordionSummary aria-controls="attendance" id="attendance">
                    <Typography variant="h5">2. Attendance and Tardiness</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Players in the league are <b>NOT</b> expected to attend all events, but in order to receive
                        credit for the league in the eyes of the IFPA a player must attend half of the league events
                        (typically 3/6). In a typical 6 event season a players best 4 nights will be added together to
                        determine the regular season score of that player. This allows the flexibility of missing a
                        night or two, while at the same time rewards players for attending all nights.
                        <Typography variant="h6" className="subheading">
                            2.1 Start Time
                        </Typography>
                        Any games in progress by league players at league start time are immediately halted. Players
                        join their assigned group and begin league play immediately.
                        <Typography variant="h6" className="subheading">
                            2.2 Announced Tardiness
                        </Typography>
                        If the SLO is notified before league start time that a player will be late, then players in the
                        affected group will wait up to 5 minutes before beginning play.
                        <Typography variant="h6" className="subheading">
                            2.3 Unannounced Tardiness
                        </Typography>
                        If a player arrives after his group begins play, the player may join the game in progress if
                        possible. Joining the game is permitted if the machine permits it, and the last player of the
                        group has not started ball 1.
                        <Typography variant="h6" className="subheading">
                            2.4 Forfeits
                        </Typography>
                        If a player is not present and eligible to play a game, a forfeit will be recorded for that
                        player. The forfeiting player will receive last place points(1) for that game. The remaining
                        players in the group will still play the game, and game points will be assigned normally. If a
                        player is not present or eligible to play a game prior to the round starting then they will be
                        removed from play and matchplay will continue accordingly.
                        <Typography variant="h6" className="subheading">
                            2.5 Inclement Conditions
                        </Typography>
                        The SLO may cancel a league match, due to inclement weather or other inclement conditions,
                        particularly if travel conditions are deemed unsafe for players. The SLO is encouraged to set
                        conditions under which league play will automatically be canceled, such as the closing of a
                        local university or government office, and advise all players of this condition prior to the
                        start of the season.
                        <Typography variant="h6" className="subheading">
                            2.6 League Cancellation
                        </Typography>
                        If a location should close down during league play, if less than two games have been played by
                        any group, the match shall be canceled and not recorded. If two or more games have been played
                        by all groups, then scores will be recorded only for those games played by all groups.
                        <p>
                            If a league match is canceled for any reason, the SLO must announce prior to the next
                            scheduled meet whether the canceled match will be dropped (shortening the season) or made
                            up.
                        </p>
                        <Typography variant="h6" className="subheading">
                            2.7 Guests
                        </Typography>
                        Anyone can join the league play at any point in the season provided we are not at maximum
                        capacity. If the player has never participated in TPL before they are not required to pay league
                        dues. Based on the current machine count the League has a hard cap of 44 players. Players who
                        participated in the previous season will have precedence over new players.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('machineplay')} onChange={handleChange('machineplay')}>
                <AccordionSummary aria-controls="machineplay" id="machineplay">
                    <Typography variant="h5">3. Machine Play Rules</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Typography variant="h6" className="subheading">
                            3.1 Extra Balls
                        </Typography>
                        On machines set to four (4) or more balls per game, extra balls must be plunged and not played.
                        Extra balls that must be plunged and not played are referred to as “unallowable” or “plunged”
                        balls in this document.
                        <p>
                            When a player is required to plunge an extra ball, the player may touch the machine to set
                            up a skill shot before launching the ball. Once the ball is set into motion, the player may
                            no longer touch the machine. If the ball is returned to a launcher lane that requires a
                            manual plunge (e.g. by a ball saver), the player may re-plunge the ball.
                        </p>
                        <p>
                            If a plunged extra ball becomes stuck somewhere on the machine, the player may attempt to
                            nudge the machine to free the ball. If nudging fails to free the ball, and there is no
                            operator present to free the ball, the player (or SLO) will be required to tilt the game in
                            an attempt to free the ball. No compensation is provided in this event, nor is it considered
                            a major malfunction. For machines requiring switch validation a player is allowed to take
                            one flip at a time until the playfield is validated and the ball saver takes effect.
                        </p>
                        <Typography variant="h6" className="subheading">
                            3.2 Replays and Specials
                        </Typography>
                        No award is given for credits earned by replays or specials during league play. If a machine
                        awards extra balls for replays or specials, they are played as prescribed in section 3.1.
                        <Typography variant="h6" className="subheading">
                            3.3 Malfunctions
                        </Typography>
                        Pinball machines are complex assemblies that can exhibit many unintended behaviors during play.
                        To keep league play on track and prevent excessive focus on minor glitches, only serious machine
                        malfunctions can affect league play. Serious machine malfunctions are broken down into two
                        categories: major malfunctions and catastrophic malfunctions.
                        <p>
                            A <b>major malfunction</b> is one that results in a loss of a playable turn that is not a
                            normal part of the game (i.e. premature loss of turn). A “playable turn” includes the
                            player’s current turn at play, and any other balls that the player is entitled to play. This
                            does not include “unallowable” extra balls. Note that an active multiball is part of the
                            “current turn at play” and therefore a major malfunction during multiball is only counted
                            once. In disputed situations, the SLO shall decide whether or not a malfunction is
                            considered major.
                        </p>
                        <p>The following are examples of major malfunctions:</p>
                        <ul>
                            <li>
                                A player is forced to tilt the ball in an attempt to dislodge a stuck ball (unless it is
                                an unallowable extra ball; see section 3.1).
                            </li>
                            <li>A turn ends prematurely (i.e. with 1 or more balls in play).</li>
                            <li>
                                A ball is auto-plunged or otherwise shot into play without the player’s involvement,
                                resulting in loss of ball.
                            </li>
                        </ul>
                        <p>The following examples would not be considered major malfunctions:</p>
                        <ul>
                            <li>A player tilts away a stuck ball when it was not clearly necessary.</li>
                            <li>A multiball round ends prematurely but does not result in loss of turn.</li>
                            <li>A ball goes airborne and drains.</li>
                            <li>A lit kickback fails to kick the ball back into play.</li>
                            <li>A ball saver fails to work.</li>
                            <li>A player tilts another player’s ball. (This is Interference.)</li>
                            <li>
                                If a problem with a machine is announced to league players by the SLO before league play
                                is started, then that problem is not considered a major malfunction even if the result
                                is loss of ball in play.
                            </li>
                        </ul>
                        <p>
                            <b>Catastrophic malfunctions</b> deny a player a playable turn without that player having
                            any opportunity to play the ball. As with major malfunctions, this does not include
                            “unallowable” extra balls. The following are examples of catastrophic malfunctions:
                        </p>
                        <ul>
                            <li>Slam tilt</li>
                            <li>Total machine failure / reset </li>
                            <li>Loss of electrical power</li>
                            <li>Fire due to overheated components</li>
                        </ul>
                        <p>
                            Note that these events are not considered catastrophic for the ball the player is currently
                            playing, provided the ball was put into play before the malfunction occurred. That player
                            receives a major malfunction, not a catastrophic malfunction. In disputed situations, the
                            SLO shall decide whether or not a malfunction is considered catastrophic.
                        </p>
                        <p>
                            If a major malfunction or catastrophic malfunction occurs to a player during the course of a
                            game, the player will be allowed to play as many balls on a new game of the same machine as
                            were affected by malfunction on the original game. After those balls are played, the
                            displayed score from the new game is added to the displayed score from the original game to
                            calculate the player’s final overall score for the game. Affected balls include any balls
                            prematurely ended by major malfunction and any balls never launched into play, including
                            collected allowable extra balls, but not uncollected or unallowable extra balls. At the
                            SLO‘s discretion, balls to be played on the new game may start with other than ball 1, in
                            which case the leading ball(s) must be plunged and the displayed score from those ball(s)
                            subtracted from the player’s final score. At the SLO‘s discretion, game features may be set
                            on the new game to match the known state of the original game, subtracting any incidental
                            points required to establish this state from the player’s final score. The rest of the group
                            waits for the player to finish the compensatory game before starting their next game.
                        </p>
                        <p>
                            The SLO can declare a machine unplayable at any time if it is not functioning properly and
                            the resulting malfunction(s) will, in his estimation, impair the ability of players to
                            obtain fair scores. In these cases, the entire game is replayed immediately on a machine
                            designated by the SLO. Any recorded scores on the machine at the time of failure will be
                            used if the machine is brought back into service and affected players replay, or players
                            accept agreed-upon scores.
                        </p>
                        <p>
                            It is recommended that the league prepare a maintenance sheet on which is noted any
                            malfunctions that are found on the various machines during league play. This list should be
                            passed on to the site’s management to assist in the proper maintenance of the machines.
                        </p>
                        <Typography variant="h6" className="subheading">
                            3.4 Positive Malfunctions
                        </Typography>
                        If a malfunction causes a player to receive an exceptionally unfair advantage over the other
                        players, and there is no reasonable way to avoid it, then the game is stopped and a new game is
                        started either on the same or a different machine at the SLO‘s discretion. If a positive
                        malfunction can be avoided (such as the awarding of extra points by repeated tapping of a
                        flipper button), then this behavior shall be reported to the SLO and shall be avoided during
                        subsequent league play. At the discretion of the SLO, the game may be replayed if it is felt
                        that an unfair advantage was already gained by one or more players due to the malfunction. In
                        this situation, the SLO may also rule that completed scores on the game are to be discarded. It
                        is the responsibility of all members of a group to ensure that positive malfunctions are not
                        abused.
                        <p>The following are not automatically considered to be “exceptionally unfair advantages”:</p>
                        <ul>
                            <li>A one ball “multiball” or stuck ball during multiball</li>
                            <li>
                                A ball that drains so forcefully that it works its way back to the plunger lane (“trough
                                passthrough”)
                            </li>
                        </ul>
                        <p>
                            One ball multiball is not a major malfunction but should be stopped as soon as noticed, or
                            pointed out by another player to alert the current player to trap up to try to free the
                            stuck ball. If the ball is not able to be freed or the multiball ends during the attempt to
                            free the ball then no compensation is provided.
                        </p>
                        <Typography variant="h6" className="subheading">
                            3.5 Gameplay Promptness
                        </Typography>
                        When a player’s turn comes up in a league match, he is expected to begin play promptly. If a
                        league player does not begin a chosen match in a reasonable amount of time (2 minutes), the SLO
                        may plunge the ball for him, and the player may not play the ball. The player also may let the
                        tilt bob rest for up to 2 minutes. Once a match has begun all players are responsible for
                        continuing the flow of the game and minimizing the risk of distractions. See the following
                        sections for more information.
                        <Typography variant="h6" className="subheading">
                            3.6 Distractions
                        </Typography>
                        In general, random distractions that occur during league play (including minor physical bumps)
                        are considered normal play conditions and no allowances are made for the effects of such
                        distractions on a player’s game.
                        <Typography variant="h6" className="subheading">
                            3.7 Non-League Players
                        </Typography>
                        League players do not take precedence over other customers at the establishment. Having a
                        non-league customer play your ball is considered a distraction and not interference; control of
                        the ball should be regained as quickly and politely as possible. Close attention should be paid
                        by league players to their game in progress to guard against this situation. If control can not
                        be obtained then the SLO can consider compensation but is not required to do so.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('illegal')} onChange={handleChange('illegal')}>
                <AccordionSummary aria-controls="illegal" id="illegal">
                    <Typography variant="h5">4. Illegal actions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        <Typography variant="h6" className="subheading">
                            4.1 Playing Own Unallowable Extra Ball
                        </Typography>
                        If a player nudges, flips, or otherwise plays his own unallowable extra ball, he must stop as
                        soon as the error is recognized, and must plunge his next “allowable” ball without playing. If
                        the error occurs on or after the player’s last “allowable” ball, his final machine score is
                        reduced by 25%.
                        <Typography variant="h6" className="subheading">
                            4.2 Playing Opponent’s Ball
                        </Typography>
                        The violator shall attempt to trap the ball(s) on a flipper as soon as the error is realized.
                        This is Interference, and the violator will receive a score of last place (1) for the game.
                        <p>
                            If the affected ball was an unallowable extra ball, and the victim has no more allowable
                            balls, there is no additional compensation for the victim. Otherwise, the victim may choose
                            one of three options: continue playing the erroneously plunged ball (if control can be
                            recovered), drain the plunged ball and play an additional ball through a buy-in or (normally
                            unallowable) earned extra ball, or a replay of the entire game if the game is still on ball
                            one. The player can request a replay of the entire game but for times sake the SLO can
                            decline a replay in favor of one of the other two modes of compensation. The player must
                            announce a decision to all players in the group before play resumes. The deciding player is
                            responsible for ensuring that the next player does not begin play before a decision is
                            announced. If he continues play without announcing a decision, then no other compensation
                            will be provided. If the game is replayed, the second (replay) score becomes his score on
                            that game, regardless of whether it is better or worse than his previous effort. The rest of
                            the group waits for the player to finish the replayed game before starting their next game.
                        </p>
                        <Typography variant="h6" className="subheading">
                            4.3 Interference
                        </Typography>
                        Interference in another player’s game is not tolerated. Interference includes (but isn’t limited
                        to) intentional slam tilts, tilting an opponent’s ball, or nudging the machine during another
                        player’s ball, even if the action does not cause the victim to lose the ball. It also includes
                        intentional distraction of a player during his play. Talking is not considered interference,
                        unless the player at the machine specifically requests that he not be talked to during play.
                        <p>
                            If a player interferes with another player, causing a drain and/or loss of turn, the victim
                            of the interference may either replay the entire game (if the game is still on ball one), or
                            continue the game and play one (1) additional ball to replace the interfered ball (using
                            either an earned, normally unallowable extra ball or a buy-in ball). If the next player
                            starts play with no decision announced, the victim is presumed to wish to continue his game.
                            The interfering player is required to pay for the replayed game or the buy-in ball, even if
                            there are credits on the machine.
                        </p>
                        <p>
                            <b>Coaching</b> is the act of talking to a player about the game during the play of their
                            ball. This is <b>not</b> allowed during league play. Talking to players in between balls
                            about game strategy is not considered coaching and is allowed.
                        </p>
                        <p>
                            In the event that a league player or non-league player causes another player to receive one
                            or more dangers (either tilting through or other means), the player may either choose to
                            play with the danger(s) or request a compensation ball.
                        </p>
                        <Typography variant="h6" className="subheading">
                            4.4 Slam Tilts
                        </Typography>
                        An <b>intentional slam tilt</b> is one caused by an aggressive and excessive shove of the
                        machine, or by an attempted bangback or deathsave, and is considered interference. Any other
                        slam tilt is considered accidental. All slam tilts are handled as catastrophic malfunctions.
                        <Typography variant="h6" className="subheading">
                            4.5 Serious Violations of League Rules
                        </Typography>
                        Serious violations are those so designated in these rules, as well as any conduct by a player
                        that the SLO determines to be exceptionally detrimental to the league.
                        <p>
                            Serious violations are cumulative over an entire season, not just one match. For these
                            violations, the following penalties are assessed:
                        </p>
                        <ul>
                            <li>
                                First and second offense: Forfeit the current game with a machine score of zero. If the
                                violator’s group does not have a “current” game in progress, this penalty will be
                                assessed against the game of the current match for which the violator has the highest
                                league points. If there is more than one such game, the last such game of the match will
                                be penalized.
                            </li>
                            <li>
                                Third offense: Forfeit of all games in the current match with machine scores of zero.
                                Behavior which causes a player to be ejected from the establishment by the management
                                will be penalized as an automatic third offense, even if it occurs before or after
                                league play. If the violator’s group does not have a “current” match in progress, the
                                match chronologically closest to the violation will be penalized.
                            </li>
                            <li>
                                Fourth offense: Forfeit of season. The player’s scores are wiped, and the player will be
                                suspended from the league. Violence of any kind against fellow players, vandalism of
                                pinball machines or other property will be penalized as an automatic fourth offense.
                            </li>
                        </ul>
                        <Typography variant="h6" className="subheading">
                            4.6 Not Starting the Proper Number of Games
                        </Typography>
                        If too many games are started inadvertently, balls for the extra games are plunged but not
                        played. If too few games are started, additional games are started, if possible, so that the
                        number of games on the machine matches the number of players in the group. In these cases, no
                        further action is required.
                        <p>
                            If the proper number of games cannot be started by the above means, the game is ended
                            immediately, voiding all players’ scores. A new game is started on the same machine with the
                            correct number of players. All players in a group are responsible for ensuring the correct
                            number of games has been started and all players should split the cost of restarting the
                            game.
                        </p>
                        <Typography variant="h6" className="subheading">
                            4.7 Deathsaves and Bangbacks
                        </Typography>
                        Death saves and bangbacks (“biffs”) are techniques used by some players to return a ball back
                        into play that has already gone down an outlane or otherwise drained. These techniques are{' '}
                        <b>not</b> allowed in TPL league play. A player that successfully performs a deathsave or
                        bangback will receive a machine score of zero on that game, and must plunge any remaining balls
                        without playing them. However, it is allowable for the ball to bounce back into play of its own
                        accord.
                        <p>
                            Since these maneuvers do not interfere with any other player’s game, performing a deathsave
                            or bangback is not considered a serious violation of league rules.
                        </p>
                        <Typography variant="h6" className="subheading">
                            4.8 Cheating
                        </Typography>
                        Pinball can often be frustrating, especially during competition. The TPL rules are designed to
                        deal fairly with this fact, to encourage people to control themselves, and to compensate for
                        various mishaps that might occur during play. On the other hand, violation of any rules with the
                        clear intent of preventing another player from fairly playing the machine or of unfairly
                        increasing one’s own score can only be described as cheating, and is not tolerated. Cheating
                        will result in the player’s immediate suspension from the league.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('groups')} onChange={handleChange('groups')}>
                <AccordionSummary aria-controls="groups" id="groups">
                    <Typography variant="h5">5. Player Grouping—Non-Playoff Matches</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Players are arranged into balanced groups of three or four. A match consists of 4 games played
                        on 4 machines during a single meet. All games are played in multiplayer mode (players alternate
                        turns and scores are displayed simultaneously on the machine). At the end of each match, scores
                        are recorded for the purpose of awarding badges through the TPL software. One person from each
                        group should take a picture of each score and present it to an SLO. It is the individual players
                        responsibility to double check their finishing position at the conclusion of the round and
                        notify the SLO if a score was entered incorrectly.
                        <Typography variant="h6" className="subheading">
                            5.1. Group Size
                        </Typography>
                        If the number of players is a multiple of 4, players will be arranged in groups of 4. If the
                        number of players is not a multiple of 4, players are arranged into as many groups of 4 players
                        as possible, with the remaining groups having 3 players.
                        <Typography variant="h6" className="subheading">
                            5.2. Group Scoring
                        </Typography>
                        Points will be awarded using IFPA-style scoring: 4 player group - 7/5/3/1 3 player group -
                        7/4/1. Your total score is added up at the end of the night and that is your score for the
                        pincounter.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('selection')} onChange={handleChange('selection')}>
                <AccordionSummary aria-controls="selection" id="selection">
                    <Typography variant="h5">6. Regular Season Machine Selection</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Machines for the evening will be chosen by the league commissioner at the beginning of a
                        pincounter. If you notice an issue with a machine during warmups please notify an SLO and they
                        will act accordingly. Once in matchplay, machines are chosen randomly. The software will attempt
                        to vary the machines but prioritizes balanced matchplay over balanced machines so you may play
                        the same machine twice in one night.
                        <Typography variant="h6" className="subheading">
                            6.1 Machine Replacement
                        </Typography>
                        If a machine that was originally deemed playable by an SLO has an issue, it may be removed from
                        the tournament by the SLO. If a major malfunction happens in the middle of a round the machine
                        will be removed from play and the round will be replayed on a randomly drawn machine.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('finale')} onChange={handleChange('finale')}>
                <AccordionSummary aria-controls="finale" id="finale">
                    <Typography variant="h5">7. Finale</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        The top 12 qualifying (players must compete in at least 3/6 Pincounters to qualify) participants
                        will compete in the Season Finale. The top 4 receive a first round bye. If 4 out of the 12
                        finalists can not make the finale date then the finale will be rescheduled to accommodate. If 3
                        or less players cannot make the finale date then lower seeds will be brought into the top 12 and
                        the finale will proceed as planned.
                        <Typography variant="h6" className="subheading">
                            7.1 Finale Games
                        </Typography>
                        Season Finale participants will be matched head-to-head with slaughter style seeding with the
                        top 4 receiving a first round bye. (5v12, 6v11, 7v10, 8v9). The higher seed has a choice of game
                        or position for Game 1. A player may not choose the same machine twice in a single round. The
                        loser of Game 1 has a choice of game or position for Game 2. The loser of Game 2 has a choice of
                        game or position for Game 3 and so on. With a certain number of wins (2 in a best of 3 or 3 in a
                        best of 5), a player advances to the next round. Players will not be reseeded between rounds.
                        Best-of-X continues until one player remains. The third/fourth place tie break game will also
                        take place and is typically a best of 3 unless the players agree to less games. Selecting
                        players may wait on games only if the game in question is more than half way through. For
                        example in a two player game, the players must be on player 2 ball 2. If a non-league player is
                        occupying a game you may politely ask to play at the end of their game, if they refuse then you
                        must pick a different game.
                        <Typography variant="h6" className="subheading">
                            7.2 Tiebreaks
                        </Typography>
                        For players with an equal number of points from the regular season, a one-ball game will be
                        played on a random machine to determine seeds before the Finale begins at 7pm. The exception to
                        this is a tie for 12th place in order to get into the finale. 12th place ties will be broken the
                        night of the final pincounter with a full game on a randomly chosen machine.
                        <Typography variant="h6" className="subheading">
                            7.3 Consolation
                        </Typography>
                        After the first round of the finale the four eliminated players may choose to play a 3 game
                        group matchplay with 4/2/1/0 scoring to determine the ordering for 9th through 12th place. If a
                        player chooses not to play in the consolation match then they are essentially forfeiting and
                        will receive last. If multiple players chose not to play in the consolation match then the
                        higher seed will finish above lower seeds. (Eg. Seed 4 and 5 choose not to play, seed 4 will
                        finish in 11th place and seed 5 will finish in 12th place). Players who do chose to play will
                        continue regardless with either 3 player scoring or heads up best of 3. Similarly after the
                        second round the 4 eliminated players may choose to play a 3 game group matchplay with the same
                        rules as above.
                        <Typography variant="h6" className="subheading">
                            7.4 IFPA Submission
                        </Typography>
                        At the end of the season the final standings will be calculated by taking the finale results for
                        the top 12 finishers and combining that with the regular season results. For example if you
                        finish first in the regular season and 8th in the finale then you finish in 8th, however you can
                        do no worse than 8th if you qualify for the finale with a bye and no worse than 12th if you
                        qualify for the finale without a bye.
                        <Typography variant="h6" className="subheading">
                            7.5 Non-finale strikes tournament
                        </Typography>
                        Often we will choose to run the finale concurrently with the 3rd Wednesday strikes tournament.
                        This tournament is only for players that did not qualify in the top 12 and has no bearing on the
                        final standings. If you qualify in the top 12 and choose not to play in the finale you may play
                        in the strikes tournament but you must make this decision on the date of the final pincounter so
                        an appropriate person can take your finale spot and you will be bumped down to 13th place in the
                        IFPA submission.
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={!unexpanded.includes('dues')} onChange={handleChange('dues')}>
                <AccordionSummary aria-controls="dues" id="dues">
                    <Typography variant="h5">8. Dues</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography>
                        Players are responsible for coin drops. Players may choose to buy-in to each Pincounter session
                        for $2/session, or pay a one-time fee of $10 to gain entry to all 6 sessions + finale. If this
                        is your first pincounter ever and you just want to try it out to see if you might like it then
                        it&apos;s on the house. $1 per IFPA-qualifying person will be subtracted from the total pot to
                        submit regular season results to IFPA and a small fee for site hosting/domain. The remaining
                        Cash + Prizes will be awarded to the top 4 participants of the Season Finale. The payout will be
                        50/25/15/10. Meaning that first place in the season finale will get 50% of the total cash prize
                        pot, second will get 25% and so forth.
                    </Typography>
                </AccordionDetails>
            </Accordion>
        </div>
    );
}
