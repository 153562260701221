import { useEffect, useState, useCallback } from 'react';
import { useFetchWrapper } from '@apis/Utils';
import { ImportStatus, ImportJob } from '@apis/Types';

type Error = {
    error: string;
};

export function useImportJob(id: string): { job: ImportJob; error: Error; refresh: () => void } {
    const [job, setJob] = useState(null);
    const [error, setError] = useState(null);
    const { get } = useFetchWrapper();

    const refresh = useCallback(() => {
        get(`import/${id}`)
            .then((data) => {
                setJob(data);
            })
            .catch((err) => {
                setError(err);
            });
    }, [id]);

    useEffect(refresh, [refresh]);

    return { job, error, refresh };
}

export function useAllImportStatuses(): { importStatuses: ImportStatus[]; error: Error; refresh: () => void } {
    const [importStatuses, setImportStatuses] = useState(null);
    const [error, setError] = useState(null);
    const { get } = useFetchWrapper();

    const refresh = useCallback(() => {
        get(`import/`)
            .then((data) => {
                setImportStatuses(data);
            })
            .catch((err) => {
                setError(err);
            });
    }, []);

    useEffect(refresh, [refresh]);

    return { importStatuses, refresh, error };
}

export function useImportActions() {
    const { post, del } = useFetchWrapper();

    function createImport(season: string, tournament: string, finale = false, oneOff = false): Promise<void> {
        return post('import/', { season, tournament, finale, oneOff });
    }

    function stopImport(tournament: string): Promise<void> {
        return del(`import/${tournament}`);
    }

    return { createImport, stopImport };
}
