import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Paper from '@mui/material/Paper';
import BadgeIcon from '@components/BadgeIcon';
import Special from '@components/Special';

export default function ImportStatus({ status, loading }) {
    if (loading)
        return (
            <Box sx={{ display: 'flex', marginTop: '50px' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );

    if (!status) return null;

    const { arenas, players, badges, results, errors, specials } = status;
    const hasResults = results && results.length > 0;

    function empty() {
        return !(
            arenas?.length > 0 ||
            players?.length > 0 ||
            badges?.length > 0 ||
            results?.length > 0 ||
            errors?.length > 0 ||
            specials?.length > 0
        );
    }

    return (
        <Paper sx={{ padding: '10px' }}>
            {empty() ? <Typography align="center"> No Results</Typography> : null}
            {hasResults ? <Typography variant="h6"> Results </Typography> : ''}
            <div>{hasResults ? `Created ${results.length} results.` : ''}</div>
            {arenas && arenas.length > 0 ? (
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Arenas ({arenas.length})
                </Typography>
            ) : (
                ''
            )}
            {arenas && arenas.map((arena) => <div key={arena._id}>Created {arena.name}.</div>)}
            {players && players.length > 0 ? (
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Players ({players.length})
                </Typography>
            ) : (
                ''
            )}
            {players && players.map((player) => <div key={player._id}>Created {player.name}.</div>)}
            {badges && badges.length > 0 ? (
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Badges ({badges.length})
                </Typography>
            ) : (
                ''
            )}
            {badges &&
                badges.map((badge, index) => (
                    <div key={index}>
                        <Box sx={{ display: 'inline-block', verticalAlign: 'middle', marginRight: '10px' }}>
                            <BadgeIcon badge={badge.badge} />
                        </Box>
                        Added {badge.badge.display} to {badge.player.name}.
                    </div>
                ))}

            {specials && specials.length > 0 ? (
                <Typography variant="h6" sx={{ marginTop: 2 }}>
                    Specials Awarded ({specials.length})
                </Typography>
            ) : (
                ''
            )}
            {specials &&
                specials.map((player) => (
                    <Box key={player._id}>
                        <Special player={player} />
                        <Box component="span" sx={{ paddingLeft: '10px' }}>
                            Assigned special to {player.name}.
                        </Box>
                    </Box>
                ))}

            {errors && errors.length > 0 ? (
                <Typography variant="h6" sx={{ marginTop: 2, color: 'red' }}>
                    Errors ({errors.length})
                </Typography>
            ) : (
                ''
            )}
            {errors &&
                errors.map((error) => (
                    <Box key={error} sx={{ color: 'red' }}>
                        {error}
                    </Box>
                ))}
        </Paper>
    );
}
