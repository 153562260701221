import Chip from '@mui/material/Chip';
import Special from '@components/Special';

export default {
    Virus: [
        {
            field: 'roundsSurvived',
            headerName: 'Streak',
            valueGetter: (params) => params.row.special?.roundsSurvived || 0,
            editable: false,
            width: 100,
        },
        {
            field: 'infected',
            headerName: 'Infected',
            renderCell: (params) =>
                params.value ? <Chip label="Infected" color="error" /> : <Chip label="Clean" color="success" />,
            valueGetter: (params) => params.row.special?.infected,
            editable: false,
            width: 100,
        },
        {
            field: 'zero',
            headerName: 'Patient Zero',
            renderCell: (params) =>
                params.value ? <Chip label="Zero" color="error" /> : <Chip label="Clean" color="success" />,
            valueGetter: (params) => params.row.special?.patientZero,
            editable: false,
            width: 100,
        },
    ],
    Smackdown: [
        {
            field: 'beltHolder',
            headerName: 'Belt Holder',
            renderCell: (params) => (params.value ? <Special player={params.value} /> : null),
            valueGetter: (params) => params.row,
            editable: false,
            width: 100,
        },
        {
            field: 'beltMatches',
            headerName: 'Belt Matches',
            valueGetter: (params) => params.row.special?.beltMatches || 0,
            editable: false,
            width: 100,
        },
        {
            field: 'beltsDefended',
            headerName: 'Belts Defended',
            valueGetter: (params) => params.row.special?.beltsDefended || 0,
            editable: false,
            width: 100,
        },
        {
            field: 'streak',
            headerName: 'Streak',
            valueGetter: (params) => params.row.special?.streak || 0,
            editable: false,
            width: 100,
        },
    ],
};
