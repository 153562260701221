import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';

import ArenaLink from '@components/ArenaLink';

function ArenaRow({ arena, columns }) {
    return (
        <TableRow
            key={arena._id}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0,
                },
            }}
        >
            <TableCell component="th" scope="row">
                <ArenaLink arena={arena} />
            </TableCell>
            {columns.map((c) => (
                <TableCell component="td" scope="row" key={c.property}>
                    {arena[c.property] && arena[c.property].toLocaleString()}
                </TableCell>
            ))}
        </TableRow>
    );
}

export default function ArenaCard({ arenas, columns, fixed = false, stencils = 4, title = 'Arenas' }) {
    if (!arenas) {
        return (
            <>
                <Skeleton variant="text" sx={{ margin: '10px' }} />
                <div className="skeleton-wrapper">
                    {columns.map((c) => (
                        <div className="skeleton-col" key={c.property}>
                            {Array.from({ length: stencils }, (_, k) => (
                                <Skeleton key={k} variant="text" sx={{ margin: '5px' }} />
                            ))}
                        </div>
                    ))}
                </div>
            </>
        );
    }
    if (arenas.length === 0)
        return (
            <Typography align="center" sx={{ padding: '20px' }}>
                No results
            </Typography>
        );

    return (
        <TableContainer component={Paper}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" className="table-title" component="div">
                {title}
            </Typography>
            <Table
                sx={{ minWidth: 300, tableLayout: fixed ? 'fixed' : 'auto' }}
                size="small"
                aria-label="a dense table"
            >
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell>Arena Name</TableCell>
                        {columns.map((c) => (
                            <TableCell key={c.label}>{c.label}</TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {arenas.map((arena) => (
                        <ArenaRow arena={arena} columns={columns} key={arena._id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
