import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { usePlayersWithUsers } from '@apis/Players';
import { useSeason } from '@apis/Seasons';
import ManageUserActions from '@components/admin/ManageUsersActions';
import ManageUsersTable from '@components/admin/ManageUsersTable';
import { showSnack } from '@utils/Events';

export default function ManageUsers() {
    const [selectionModel, setSelectionModel] = useState([]);
    const { players, refreshPlayers, error } = usePlayersWithUsers();
    const { season } = useSeason('current');

    useEffect(() => {
        if (error) showSnack('error', error.error);
    }, [error]);

    if (!players && !error)
        return (
            <Box sx={{ display: 'flex', marginTop: '50px' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );

    if (error || players.length === 0) return <Typography align="center">No results</Typography>;

    return (
        <Box sx={{ padding: '5px' }}>
            <Box sx={{ marginLeft: 'auto', marginRight: 0, marginBottom: '20px', width: 'fit-content' }}>
                <ManageUserActions
                    plugin={season?.plugin}
                    selectionModel={selectionModel}
                    players={players}
                    refreshPlayers={refreshPlayers}
                    size={2}
                />
            </Box>
            <Box sx={{ height: 600, width: '100%', position: 'relative' }}>
                <ManageUsersTable
                    players={players}
                    error={error}
                    plugin={season?.plugin}
                    selectionModel={selectionModel}
                    setSelectionModel={setSelectionModel}
                />
            </Box>
        </Box>
    );
}
