import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

const BootstrapTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}));

export default function BadgeIcon(props) {
    const { badge, className } = props;

    const avatar = (
        <Avatar className={className} sx={{ backgroundColor: 'lightgrey' }}>
            <img alt="badge icon" src={badge.iconUri}></img>
        </Avatar>
    );

    if (props.useTooltip) {
        return <BootstrapTooltip title={`${badge.display} - ${badge.description}`}>{avatar}</BootstrapTooltip>;
    }

    return avatar;
}
