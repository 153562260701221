import { createContext } from 'react';
import { User } from '@apis/Types';

interface UserContextType {
    user: User | null;
    setUser: (User) => void;
}

export default createContext<UserContextType>({
    user: null,
    setUser: () => {},
});
