import { useEffect, useState } from 'react';
import { Season, SeasonStanding } from '@apis/Types';
import { useFetchWrapper } from '@apis/Utils';

export function usePastSeasons(enabled = true): { seasons: Season[] } {
    const [seasons, setSeasons] = useState();
    const { get } = useFetchWrapper();

    useEffect(() => {
        if (enabled) {
            get('season').then((data) => {
                if (data) {
                    setSeasons(data.filter((s) => !s.isCurrent));
                }
            });
        }
    }, [enabled]);

    return { seasons };
}

export function useSeason(id: number | string, populate = true): { season: Season } {
    const [season, setSeason] = useState();
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`season/${id}?populate=${populate}`).then((data) => {
            setSeason(data);
        });
    }, [id]);

    return { season };
}

export function useSeasonStandings(id: number | string): { standings: SeasonStanding[] } {
    const [standings, setStandings] = useState();
    const { get } = useFetchWrapper();

    useEffect(() => {
        get(`season/${id}/standings`).then((data) => {
            setStandings(data);
        });
    }, [id]);

    return { standings };
}
