import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

import Round from '@components/Round';
import StandingsTable from '@components/StandingsTable';
import ProgressBlob from '@components/ProgressBlob';
import { useTournament } from '@apis/Tournaments';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ paddingTop: '16px' }}>
                    <Typography component="div">{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Tournament() {
    const [value, setValue] = useState(0);
    const [rounds, setRounds] = useState(null);

    const { id } = useParams();
    const { tournament } = useTournament(id);

    useEffect(() => {
        if (tournament) {
            const newRounds = getRounds(tournament.games);
            setRounds(newRounds);
            if (tournament.status !== 'completed') setValue(newRounds.length - 1); // Set active tab as latest round
        }
    }, [tournament]);

    if (!rounds) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ margin: '16px' }}>
            {tournament.status !== 'completed' ? <ProgressBlob /> : ''}
            <Typography variant="h5" display="inline" sx={{ verticalAlign: 'middle' }}>
                {tournament.name}
            </Typography>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} variant="scrollable" aria-label="basic tabs example">
                    {rounds &&
                        rounds.map((round, index) => (
                            <Tab label={`Round ${index + 1}`} {...a11yProps(0)} key={index} />
                        ))}
                    <Tab label="Standings" {...a11yProps(0)} />
                </Tabs>
            </Box>
            {rounds &&
                rounds.map((games, index) => (
                    <TabPanel value={value} index={index} key={index}>
                        <Round games={games}></Round>
                    </TabPanel>
                ))}

            <TabPanel value={value} index={rounds.length} align="center">
                <StandingsTable standings={tournament.standings}></StandingsTable>
            </TabPanel>
        </Box>
    );
}

function getRounds(games) {
    return games.reduce((accum, game) => {
        accum[game.round] = accum[game.round] || [];
        accum[game.round].push(game);
        return accum;
    }, []);
}
