import { useState, useRef } from 'react';
import { usePlayerActions } from '@apis/Players';
import ActionsPlugins from '@components/admin/ManageUsersActionsPlugins';
import { showSnack } from '@utils/Events';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

export default function ManageUserActions({ selectionModel, plugin, players, refreshPlayers, size = 1 }) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const { updatePlayer, updatePlayers, approveClaim, denyClaim } = usePlayerActions();

    const defaultActions = [
        {
            label: 'Approve',
            onClick: handleApprove,
        },
        {
            label: 'Deny',
            onClick: handleDeny,
        },
        {
            label: 'Reset Special',
            onClick: handleResetSpecial,
        },
    ];

    function handleApprove() {
        // TODO bulk approving
        if (selectionModel.length === 0) showSnack('error', 'Please select a player to Approve');
        if (selectionModel.length > 1) showSnack('error', 'Bulk approving not yet implemented');

        if (selectionModel.length === 1) {
            const id = selectionModel[0];
            const playerName = players.find((p) => p._id === id).name;
            approveClaim(id)
                .then(() => {
                    showSnack('success', `Approved claim for ${playerName}`);
                    refreshPlayers();
                })
                .catch((err) => {
                    showSnack('error', `Failed to approve claim for ${playerName}. ${err.error}`);
                });
        }
    }

    function handleDeny() {
        // TODO bulk denying
        if (selectionModel.length === 0) showSnack('error', 'Please select a player to deny');
        if (selectionModel.length > 1) showSnack('error', 'Bulk denying not yet implemented');

        if (selectionModel.length === 1) {
            const id = selectionModel[0];
            const playerName = players.find((p) => p._id === id).name;
            denyClaim(id)
                .then(() => {
                    showSnack('success', `Denied claim for ${playerName}`);
                    refreshPlayers();
                })
                .catch((err) => {
                    showSnack('error', `Failed to deny claim for ${playerName}. ${err.error}`);
                });
        }
    }

    function handleResetSpecial() {
        if (selectionModel.length === 0) {
            showSnack('error', 'Please select a player to reset special');
            return;
        }

        const ids = selectionModel;

        const data = players.reduce((accum, player) => {
            if (ids.includes(player._id)) {
                accum.push({
                    _id: player._id,
                    special: null,
                });
            }

            return accum;
        }, []);

        updatePlayers(data)
            .then(() => {
                showSnack('success', `Reset special for ${data.length} players.`);
                refreshPlayers();
            })
            .catch((err) => {
                showSnack('error', `Failed to reset special for players. ${err.error}`);
            });
    }

    const handleMenuItemClick = (callback) => {
        setOpen(false);
        callback();
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }

        setOpen(false);
    };

    const selectedPlayers = players.filter((p) => selectionModel.includes(p._id), []);

    let actions = defaultActions;

    // Inject any plugin based actions
    const ActionsProvider = ActionsPlugins[plugin];
    if (ActionsProvider) {
        const provider = new ActionsProvider(selectedPlayers, {
            updatePlayer,
            updatePlayers,
            refreshPlayers,
        });

        actions = actions.concat(provider.provide());
    }

    return (
        <>
            <ButtonGroup variant="contained" ref={anchorRef} aria-label="split button">
                {[...Array(size)].map((e, i) => (
                    <Button key={actions[i].label} onClick={actions[i].onClick}>
                        {actions[i].label}
                    </Button>
                ))}

                {actions.length > size ? (
                    <Button
                        size="small"
                        aria-controls={open ? 'split-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select merge strategy"
                        aria-haspopup="menu"
                        onClick={handleToggle}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                ) : (
                    ''
                )}
            </ButtonGroup>
            <Popper
                style={{
                    zIndex: 1,
                }}
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu" autoFocusItem>
                                    {actions.slice(size, actions.length).map((action) => (
                                        <MenuItem
                                            key={action.label}
                                            onClick={() => handleMenuItemClick(action.onClick)}
                                        >
                                            {action.label}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
