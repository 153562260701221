import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import { Link as RouterLink } from 'react-router-dom';
import UpcomingEvents from '@components/UpcomingEvents';
import ResponsivePaper from '@components/ResponsivePaper';

import './Home.css';

function HomeRules() {
    return (
        <>
            <section>
                <Typography variant="h6">Welcome to Triangle Pinball League!</Typography>
            </section>

            <section>
                <Typography paragraph>
                    Triangle Pinball League was founded in the Spring of 2022. The league was founded to bring together
                    players of all skill levels, from those chasing{' '}
                    <Link href="https://www.ifpapinball.com/rankings/overall.php">WPPRs</Link> to the new players who
                    have never flipped a flipper before. Triangle Pinball League will host quarterly seasons at Boxcar
                    Raleigh divided into a number of pincounters (usually 6) and 1 Season Finale. Each Pincounter of the
                    season will start at 7pm on the first and second Wednesday of the month. The third Wednesday of each
                    quarter will be the Season Finale of the league on the same day as the third Wednesday strikes
                    tournament. The third Wednesday strikes tournament will function as a B league for anyone who wants
                    to play. Meaning you can play in either the finale (if you qualify) or the strikes tournament, but
                    not both. Below is a quick overview of the rules. For a full comprehensive set can be found on the{' '}
                    <Link component={RouterLink} to="/rules">
                        Rules Page
                    </Link>
                </Typography>
            </section>

            <section>
                <Typography variant="h6">How do I join?</Typography>

                <Typography paragraph>
                    Just show up at one of the dates listed on this page! If it is your first time don&apos;t worry
                    about paying, try it out and see if you enjoy it. The only limiting factor is machines. We have a
                    cap on the league at 44 players and previous players have priority so if you want to reserve a spot
                    then drop us an <Link href="mailto:admin@tpleague.fun">email</Link>.
                </Typography>
            </section>

            <section>
                <Typography variant="h6">Pincounter Format - Balanced Match Play</Typography>

                <Typography paragraph>
                    Pincounters will start at 7pm. We will play a set number of rounds of balanced match play. This may
                    vary depending on the season but is generally either 4 or 5 rounds. Players will be randomly matched
                    in the first round. For rounds the remaining players will be matched with previously unplayed
                    players on previously unplayed machines (where possible). Points will be awarded using IFPA-style
                    scoring: 4 player group - 7/5/3/1 3 player group - 7/4/1
                    <br />
                    <br />
                    <b>Regular Season Totals</b>
                    <br />
                    At the end of the regular season, a player’s top 4 point finishes for each individual Pincounter
                    will be added together to determine their standing. The top 12 will qualify for the finals with the
                    top 4 receiving a first round bye.
                </Typography>
            </section>

            <section>
                <Typography variant="h6">Season Finale Format - Best-of-X</Typography>

                <Typography paragraph>
                    The top 12 qualifying (players must compete in at least 3/6 Pincounters to qualify) participants
                    will compete in the Season Finale. Season Finale participants will be matched head-to-head with
                    slaughter style seeding. For players with an equal number of points from the regular season, a
                    one-ball game will be played on a random machine to determine seeds before the Finale begins at 7pm.
                    The higher seed has a choice of game or position for Game 1. A player may not choose the same
                    machine twice in a single round. The loser of Game 1 has a choice of game or position for Game 2.
                    The loser of Game 2 has a choice of game or position for Game 3 and so on. With a certain number of
                    wins (2 in a best of 3 or 3 in a best of 5), a player advances to the next round. Players will not
                    be reseeded between rounds. Best-of-X continues until one player remains.
                    <br /> <br />
                    <b>IFPA Submission</b>
                    <br />
                    The top 12 players will be re-seeded based on where they finish in the finale and those results will
                    be submitted to the ifpa. Meaning even if you were the number one qualifier and you finish 8th then
                    you will finish in 8th place for the season. The other remaining players will remain in the same
                    position regardless of their participation in the consolation strikes tournament.
                </Typography>
            </section>

            <section>
                <Typography variant="h6">Cost/Prizes</Typography>

                <Typography paragraph>
                    Players are responsible for coin drops. Players may choose to buy-in to each Pincounter session for
                    $2/session, or pay a one-time fee of $10 to gain entry to all 6 sessions + finale. If this is your
                    first pincounter ever and you just want to try it out to see if you might like it then it&apos;s on
                    the house. $1 per IFPA-qualifying person will be subtracted from the total pot to submit regular
                    season results to IFPA and a small fee for site hosting/domain. The remaining Cash + Prizes will be
                    awarded to the top 4 participants of the Season Finale. The payout will be 50/25/15/10. Meaning that
                    first place in the season finale will get 50% of the total cash prize pot, second will get 25% and
                    so forth.
                </Typography>
            </section>
        </>
    );
}

export default function Rules() {
    return (
        <Grid container spacing={3}>
            <Grid item xs={12} md={9} sx={{ paddingLeft: '12px', paddingTop: '12px !important' }}>
                <ResponsivePaper sx={{ padding: 3 }}>
                    <HomeRules />
                </ResponsivePaper>
            </Grid>
            <Grid item xs={12} md={3} sx={{ paddingRight: '12px', paddingTop: '12px !important' }}>
                <ResponsivePaper sx={{ padding: 2 }}>
                    <UpcomingEvents />
                </ResponsivePaper>
            </Grid>
        </Grid>
    );
}
