export function showSnack(severity, message) {
    document.dispatchEvent(
        new CustomEvent('showsnack', {
            detail: { severity, message },
        })
    );
}

export function showDialog(content) {
    document.dispatchEvent(
        new CustomEvent('showdialog', {
            detail: { content },
        })
    );
}

export function hideDialog() {
    document.dispatchEvent(new CustomEvent('hidedialog'));
}
