import { useEffect, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

import { usePlayers, usePlayerActions } from '@apis/Players';
import { showSnack, hideDialog } from '@utils/Events';
import EditableAvatar from '@components/profile/EditableAvatar';

interface ClaimProfileProps {
    fullPage?: boolean;
}

export default function ClaimProfile({ fullPage }: ClaimProfileProps) {
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [options, setOptions] = useState([]);
    const { players } = usePlayers(true);
    const { claimPlayer } = usePlayerActions();

    function onSubmit() {
        if (selectedPlayer) {
            claimPlayer(selectedPlayer._id)
                .then(() => {
                    showSnack(
                        'success',
                        'Successfully put in a claim request. Requests are typically processed within 48 hours.'
                    );
                })
                .catch((err) => {
                    showSnack('error', err.error);
                });
        }
    }

    function handleClose() {
        hideDialog();
    }

    useEffect(() => {
        if (players) {
            setOptions(
                players.map((player) => {
                    return { label: player.name, _id: player._id };
                })
            );
        }
    }, [players]);

    return (
        <>
            {!fullPage ? (
                <DialogTitle>
                    Welcome to TPLeague.fun!
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
            ) : null}
            <DialogContent>
                <Typography>
                    Now that you have an account you need to associate this account with your player.
                </Typography>
                <Box sx={{ display: 'flex' }}>
                    <Autocomplete
                        id="player"
                        size="small"
                        value={selectedPlayer}
                        onChange={(_event, player) => {
                            setSelectedPlayer(player);
                        }}
                        options={options}
                        sx={{ width: 300, marginTop: '20px' }}
                        renderInput={(params) => <TextField {...params} label="Player" />}
                    />
                    <Button
                        color="secondary"
                        variant="contained"
                        onClick={onSubmit}
                        sx={{ height: '40px', marginTop: 'auto', marginLeft: fullPage ? '20px' : 'auto' }}
                    >
                        Claim
                    </Button>
                </Box>
                <Typography sx={{ marginTop: '15px' }}>Profile Picture:</Typography>
                <EditableAvatar sx={{ marginTop: '15px' }} />
            </DialogContent>
        </>
    );
}
