import React from 'react';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import PlayerAvatar from '@components/PlayerAvatar';

export default function LoginAvatar({ user, handleClickLogin, handleOpenUserMenu }) {
    let contents;

    if (!user)
        return (
            <Button onClick={handleClickLogin} sx={{ my: 2, color: 'white', display: 'block' }}>
                Login{' '}
            </Button>
        );

    if (user.player) {
        contents = <PlayerAvatar player={user.player} hideBadgeCount />;
    } else {
        contents = <Avatar src={user.picture || null} sx={{ cursor: 'pointer' }} />;
    }

    return (
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            {contents}
        </IconButton>
    );
}
