import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import FinalsGame from '@components/FinalsGame';

function FinalsRound({ round, count }) {
    return Object.values(round).map((game, index) => <FinalsGame key={index} game={game} count={count} />);
}

export default function FinalsBracket({ results, standings }) {
    const rounds = buildRounds(results, standings);

    return (
        <Box
            display="flex"
            sx={{
                alignItems: 'center',
                width: 'fit-content',
                marginLeft: 'auto',
                marginRight: 'auto',
                flexDirection: { xs: 'column', md: 'row' },
            }}
        >
            {rounds.map((round, index) => (
                <Box key={index} alignItems="center" justifyContent="center" sx={{ margin: '20px', height: '100%' }}>
                    <Typography variant="overline" display="block" align="center" sx={{ marginBottom: '-15px' }}>
                        {`Round ${index + 1}`}
                    </Typography>
                    <FinalsRound round={round} count={rounds.length} />
                </Box>
            ))}
        </Box>
    );
}

// Create an array of rounds, each round is a matchPlayGameId to result map
function buildRounds(results, standings) {
    const rounds = results.reduce((accum, result) => {
        const player = standings.find((s) => s.player._id === result.player._id);
        result.player.seed = player.seed + 1; // seeds are 0 based
        result.player.position = player.position;
        accum[result.round] = accum[result.round] || {};
        accum[result.round][result.matchplayGameId] = accum[result.round][result.matchplayGameId] || [];
        accum[result.round][result.matchplayGameId].push(result);
        return accum;
    }, []);

    return rounds;
}
