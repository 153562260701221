import { useState, useContext } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@mui/material/Input';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Lock from '@mui/icons-material/Lock';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Alert from '@mui/material/Alert';

import SocialLogins from '@components/login/SocialLogins';
import UserContext from '@components/login/UserContext';
import TokenContext from '@components/login/TokenContext';
import { showSnack } from '@utils/Events';
import { useUserActions } from '@apis/Users';

const validationSchema = Yup.object().shape({
    username: Yup.string().required('Username is required'),
    password: Yup.string().required('Password is required'),
});

export default function LoginForm({ changeForm, handleClose }) {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const [serverError, setServerError] = useState(null);
    const { setUser } = useContext(UserContext);
    const { setToken } = useContext(TokenContext);
    const { login } = useUserActions();

    function getError() {
        const keys = Object.keys(errors);
        if (keys && keys.length > 0) {
            return errors[keys[0]]?.message;
        }

        if (serverError) return serverError;
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit)();
        }
    }

    function handleSignupClick(e) {
        e.preventDefault();
        changeForm('signup');
    }

    function handleForgotPassword(e) {
        e.preventDefault();
        changeForm('forgot');
    }

    function onSubmit({ username, password }) {
        setServerError(null);
        login(username, password)
            .then((data) => {
                if (data) {
                    showSnack('success', `Successfully logged in ${data.user?.name}`);
                    setUser(data.user);
                    setToken(data.token);
                    handleClose();
                } else {
                    setServerError('An unknown error occured');
                }
            })
            .catch((e) => {
                setServerError(e.error || 'An unknown error occured');
            });
    }

    return (
        <>
            <DialogContent sx={{ width: '290px' }}>
                <Stack onKeyPress={handleKeyPress}>
                    {getError() ? (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {getError()}
                        </Alert>
                    ) : (
                        ''
                    )}
                    <FormControl variant="standard">
                        <InputLabel htmlFor="username">User</InputLabel>
                        <Input
                            id="username"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            {...register('username')}
                            error={!!errors.username}
                            startAdornment={
                                <InputAdornment position="start">
                                    <AccountCircle />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <Input
                            id="password"
                            type="password"
                            {...register('password')}
                            error={!!errors.password}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <Link href="" onClick={handleForgotPassword} sx={{ marginTop: '15px' }}>
                        Forgot Login?
                    </Link>
                    <Divider sx={{ marginTop: '20px' }}>Or</Divider>
                    <SocialLogins handleClose={handleClose} />
                    <div className="link-text">
                        Not a member?{' '}
                        <Link href="" onClick={handleSignupClick}>
                            Signup now
                        </Link>
                    </div>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSubmit(onSubmit)}>Login</Button>
            </DialogActions>
        </>
    );
}
