import React, { useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';

import UserContext from '@components/login/UserContext';
import { isAdmin } from '@utils/Utils';

function Pages({ pages, handleClick }) {
    return (
        <>
            {pages.map((page) => (
                <Button key={page.label} onClick={(e) => handleClick(e, page)} sx={{ my: 2, color: 'white' }}>
                    {page.label}
                </Button>
            ))}
        </>
    );
}

export default function DesktopNavbar({ pages, adminPages, onClick }) {
    const [subpages, setSubpages] = useState();
    const [submenuAnchorEl, setSubmenuAnchorEl] = useState(null);

    const { user } = useContext(UserContext);

    function openSubmenu(e) {
        setSubmenuAnchorEl(e.currentTarget);
    }

    function handleCloseSubmenu() {
        setSubmenuAnchorEl(null);
    }

    function handleMenuItemClick(event, page) {
        if (page.subpages) {
            setSubpages(page.subpages);
            openSubmenu(event);
        } else {
            onClick(page.path);
        }
    }

    function handleSubpageClick(path) {
        handleCloseSubmenu();
        onClick(path);
    }

    return (
        <Box
            sx={{
                flexGrow: 1,
                display: { xs: 'none', md: 'flex' },
            }}
        >
            <Pages pages={pages} handleClick={handleMenuItemClick} />
            {isAdmin(user) ? <Pages pages={adminPages} handleClick={handleMenuItemClick} /> : null}

            <Menu
                sx={{ mt: '45px' }}
                id="menu-appbar"
                anchorEl={submenuAnchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transitionDuration={0}
                open={Boolean(submenuAnchorEl)}
                onClose={handleCloseSubmenu}
            >
                <Box sx={{ minWidth: '150px' }}>
                    {subpages &&
                        subpages.length > 0 &&
                        subpages.map((sp) => (
                            <MenuItem key={sp.label} onClick={() => handleSubpageClick(sp.path)}>
                                <Typography textAlign="center">{sp.label}</Typography>
                            </MenuItem>
                        ))}

                    {!subpages || subpages.length === 0 ? (
                        <Typography align="center" sx={{ padding: '10px' }}>
                            No results
                        </Typography>
                    ) : null}
                </Box>
            </Menu>
        </Box>
    );
}
