import { useState } from 'react';
import { blue } from '@mui/material/colors';
import { Player, User } from '@apis/Types';

export function calcBg(player: Player, user: User) {
    const userPlayer = user?.player as Player;
    if (userPlayer?._id === player._id) return `${blue['300']} !important`;
    return '';
}

export function shorten(value: number) {
    if (value > 1000000000) {
        // billion
        return `${(value / 1000000000).toFixed(2)}B`;
    } else if (value > 100000000) {
        // 100 million
        return `${Math.round(value / 1000000)}M`;
    } else if (value > 1000000) {
        // million
        return `${(value / 1000000).toFixed(2)}M`;
    } else if (value > 1000) {
        // thousand
        return `${Math.round(value / 1000)}K`;
    }

    return value;
}

export function isAdmin(user: User) {
    return user?.role === 'admin';
}

export function capitalize(str: string) {
    if (!str) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function useSessionStorage<T>(key, defaultValue): [T, (newValue: T) => void] {
    const [value, setValue] = useState<T>(() => {
        try {
            const storedValue = window.sessionStorage.getItem(key);
            if (storedValue) {
                return JSON.parse(storedValue);
            }

            window.sessionStorage.setItem(key, JSON.stringify(defaultValue));
            return defaultValue;
        } catch (e) {
            return defaultValue;
        }
    });

    const setStoredValue = (newValue: T) => {
        try {
            window.sessionStorage.setItem(key, JSON.stringify(newValue));
        } catch (err) {
            /* gotta catch em all */
        }
        setValue(newValue);
    };

    return [value, setStoredValue];
}
