import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import PlayerAvatar from '@components/PlayerAvatar';
import PlayerLink from '@components/PlayerLink';
import UserContext from '@components/login/UserContext';
import { calcBg } from '@utils/Utils';

import './StandingsTable.css';

export default function StandingsTable(props) {
    const { user } = useContext(UserContext);

    const color = 'white';
    const bgcolor = 'secondary.main';
    const standings = props.standings;

    return (
        <TableContainer sx={{ overflow: 'hidden', minWidth: 200, maxWidth: 1200 }}>
            <Table size="small" aria-label="simple table" className="striped">
                <TableHead sx={{ bgcolor }}>
                    <TableRow>
                        <TableCell sx={{ color }}>#</TableCell>
                        <TableCell sx={{ color }}>Player</TableCell>
                        <TableCell sx={{ color }} align="right" size="small">
                            Points
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {standings.map((row) => (
                        <TableRow
                            key={row.player.name}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0,
                                },
                                bgcolor: calcBg(row.player, user),
                            }}
                        >
                            <TableCell align="left">{row.position}</TableCell>
                            <TableCell component="th" scope="row">
                                <PlayerAvatar player={row.player} />
                                <PlayerLink player={row.player} />
                            </TableCell>
                            <TableCell align="right" size="small">
                                {row.points}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}
