import { useEffect, useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import UserContext from '@components/login/UserContext';
import PlayerAvatar from '@components/PlayerAvatar';
import PlayerLink from '@components/PlayerLink';
import { red } from '@mui/material/colors';

import { useSeasonStandings } from '@apis/Seasons';
import { Player } from '@apis/Types';
import { calcBg } from '@utils/Utils';

export default function InfectedLeaderboard() {
    const { user } = useContext(UserContext);

    const [sortedStandings, setSortedStandings] = useState(null);
    const { standings } = useSeasonStandings('current');

    const color = 'white';

    useEffect(() => {
        setSortedStandings(
            standings?.sort((a, b) => {
                const aPlayer = a.player as Player;
                const bPlayer = b.player as Player;
                return bPlayer.special?.roundsSurvived - aPlayer.special?.roundsSurvived;
            })
        );
    }, [standings]);

    if (!sortedStandings) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );
    }

    return (
        <Container sx={{ padding: 0 }}>
            <Typography variant="h5" id="finals" align="center" sx={{ scrollMarginTop: 120 }}>
                Infected Leaderboard
            </Typography>

            <TableContainer sx={{ overflow: 'hidden', minWidth: 200, maxWidth: 1200 }}>
                <Table size="small" aria-label="simple table" className="striped">
                    <TableHead sx={{ bgcolor: red.A700 }}>
                        <TableRow>
                            <TableCell sx={{ color }}>Player</TableCell>
                            <TableCell sx={{ color }} align="right" size="small">
                                Streak
                            </TableCell>
                            <TableCell sx={{ color }} align="right" size="small">
                                Played
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedStandings.map((row) => (
                            <TableRow
                                key={row.player.name}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    bgcolor: calcBg(row.player, user),
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <PlayerAvatar player={row.player} />
                                    <PlayerLink player={row.player} />
                                </TableCell>
                                <TableCell align="right" size="small">
                                    {row.player.special?.roundsSurvived}
                                </TableCell>
                                <TableCell align="right" size="small">
                                    {row.player.special?.roundsPlayed}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}
