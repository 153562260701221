import { useEffect, useState, useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import UserContext from '@components/login/UserContext';
import PlayerAvatar from '@components/PlayerAvatar';
import PlayerLink from '@components/PlayerLink';

import { useSeasonStandings } from '@apis/Seasons';
import { Player, SeasonStanding } from '@apis/Types';
import { calcBg } from '@utils/Utils';

import { COUNTRIES } from '@utils/Constants';

type CountryStanding = {
    country: string;
    gold: number;
    silver: number;
    bronze: number;
};

interface CountryMap {
    [key: string]: CountryStanding;
}

// Sort season standings for individual players
function sortPlayerStandings(a: SeasonStanding, b: SeasonStanding) {
    const aPlayer = a.player as Player;
    const bPlayer = b.player as Player;
    const goldDiff = (bPlayer.special?.goldMedals || 0) - (aPlayer.special?.goldMedals || 0);
    const silverDiff = (bPlayer.special?.silverMedals || 0) - (aPlayer.special?.silverMedals || 0);

    // Break ties with silver and bronze medal diffs
    if (goldDiff === 0) {
        if (silverDiff === 0) {
            return (bPlayer.special?.bronzeMedals || 0) - (aPlayer.special?.bronzeMedals || 0);
        }
        return silverDiff;
    }

    return goldDiff;
}

// Sort country standings
function sortCountryStandings(a: CountryStanding, b: CountryStanding) {
    const goldDiff = b.gold - a.gold;
    const silverDiff = b.silver - a.silver;

    // Break ties with silver and bronze medal diffs
    if (goldDiff === 0) {
        if (silverDiff === 0) {
            return b.bronze - a.bronze;
        }
        return silverDiff;
    }
    return goldDiff;
}

// Take season standings and build an aggregate of country standings
function countryReducer(acc: CountryMap, standing: SeasonStanding) {
    const player = standing.player as Player;
    const country = player.special?.country;

    acc[country] = acc[country] || { country, gold: 0, silver: 0, bronze: 0 };
    acc[country].gold += player.special?.goldMedals || 0;
    acc[country].silver += player.special?.silverMedals || 0;
    acc[country].bronze += player.special?.bronzeMedals || 0;

    return acc;
}

export default function OlympicLeaderboard() {
    const { user } = useContext(UserContext);

    const [sortedStandings, setSortedStandings] = useState(null);
    const [countryStandings, setCountryStandings] = useState(null);

    const { standings } = useSeasonStandings('current');

    const color = 'white';

    useEffect(() => {
        setSortedStandings(standings?.sort(sortPlayerStandings));
    }, [standings]);

    useEffect(() => {
        const countryMap: CountryMap = standings?.reduce(countryReducer, {});

        if (countryMap) {
            setCountryStandings(Object.values(countryMap).sort(sortCountryStandings));
        }
    }, [standings]);

    if (!sortedStandings || !countryStandings) {
        return (
            <Box sx={{ display: 'flex' }}>
                <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />
            </Box>
        );
    }

    return (
        <Container sx={{ padding: 0 }}>
            <Typography variant="h5" id="finals" align="center" sx={{ scrollMarginTop: 120 }}>
                Olympic Leaderboard
            </Typography>

            {/* Individual leaderboard */}
            <TableContainer sx={{ overflow: 'hidden', minWidth: 200, maxWidth: 1200 }}>
                <Table size="small" aria-label="simple table" className="striped">
                    <TableHead sx={{ bgcolor: 'secondary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color }}>Player</TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/gold-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Gold
                            </TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/silver-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Silver
                            </TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/bronze-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Bronze
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sortedStandings.map((row) => (
                            <TableRow
                                key={row.player.name}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    bgcolor: calcBg(row.player, user),
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    <PlayerAvatar player={row.player} />
                                    <PlayerLink player={row.player} />
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.player.special?.goldMedals || 0}
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.player.special?.silverMedals || 0}
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.player.special?.bronzeMedals || 0}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h5" id="finals" align="center" sx={{ marginTop: 6 }}>
                Country Leaderboard
            </Typography>

            {/* Country leaderboard */}
            <TableContainer sx={{ overflow: 'hidden', minWidth: 200, maxWidth: 1200 }}>
                <Table size="small" aria-label="simple table" className="striped">
                    <TableHead sx={{ bgcolor: 'secondary.main' }}>
                        <TableRow>
                            <TableCell sx={{ color }}>Country</TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/gold-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Gold
                            </TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/silver-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Silver
                            </TableCell>
                            <TableCell sx={{ color }} align="center" size="small">
                                <Box component="span" sx={{ paddingRight: 0.5 }}>
                                    <img src="/images/bronze-medal.png" alt="Gold Medal" height="16px" width="16px" />
                                </Box>
                                Bronze
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {countryStandings.map((row) => (
                            <TableRow
                                key={row.country}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                }}
                            >
                                <TableCell component="th" scope="row">
                                    {`${COUNTRIES[row.country].name} (${COUNTRIES[row.country].shortName})`}
                                    <Box sx={{ display: 'inline-block', verticalAlign: 'middle', paddingLeft: '5px' }}>
                                        <img
                                            height="24px"
                                            width="24px"
                                            alt={COUNTRIES[row.country].name}
                                            src={COUNTRIES[row.country].image}
                                        ></img>
                                    </Box>
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.gold || 0}
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.silver || 0}
                                </TableCell>
                                <TableCell align="center" size="small">
                                    {row.bronze || 0}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box sx={{ display: 'flex' }}>
                {COUNTRIES.map((country, i) => (
                    <Box key={country.name} sx={{ flexGrow: 1, margin: 1 }}>
                        <Typography variant="h5" id="finals" align="center" sx={{ marginTop: 6 }}>
                            {country.shortName}
                        </Typography>
                        <TableContainer sx={{ overflow: 'hidden', minWidth: 200, maxWidth: 300 }}>
                            <Table size="small" aria-label="simple table" className="striped">
                                <TableHead sx={{ bgcolor: 'secondary.main' }}>
                                    <TableRow>
                                        <TableCell sx={{ color }}>Player</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {standings
                                        .map((s) => s.player as Player)
                                        .filter((p) => p.special?.country === i)
                                        .map((p) => (
                                            <TableRow
                                                key={p._id}
                                                sx={{
                                                    '&:last-child td, &:last-child th': {
                                                        border: 0,
                                                    },
                                                }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    <PlayerLink player={p} />
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Box>
                ))}
            </Box>
        </Container>
    );
}
