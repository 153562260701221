import { useState, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import NotificationsIcon from '@mui/icons-material/Notifications';

import { useUserActions } from '@apis/Users';
import UserContext from '@components/login/UserContext';

function NotificationList({ notifications }) {
    if (!notifications || notifications.length === 0) {
        return (
            <Typography textAlign="center" sx={{ padding: '20px' }}>
                No notifications
            </Typography>
        );
    }
    return (
        <Box
            sx={{
                '.notification:last-child .divider': {
                    display: 'none',
                },
            }}
        >
            {notifications.map((notification) => (
                <Box key={notification.message} className="notification">
                    <Box sx={{ display: 'flex', padding: '15px' }}>
                        <Box component="span" sx={{ marginRight: '15px' }}>
                            <Avatar sx={{ backgroundColor: 'lightgrey' }}>
                                <img src={notification.iconUri}></img>
                            </Avatar>
                        </Box>
                        <Typography sx={{ marginTop: 'auto', marginBottom: 'auto' }}>{notification.message}</Typography>
                    </Box>
                    <Divider className="divider" />
                </Box>
            ))}
        </Box>
    );
}

export default function NotificationBell({ user, sx }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const { setUser } = useContext(UserContext);
    const { clearNotifications } = useUserActions();

    function handleCloseMenu() {
        setAnchorEl(null);
        clearNotifications().then((data) => {
            setUser(data);
        });
    }

    function handleOpenMenu(e) {
        setAnchorEl(e.currentTarget);
    }

    if (!user) return null;

    const { notifications = [] } = user;

    return (
        <>
            <IconButton size="large" color="inherit" sx={sx} onClick={handleOpenMenu}>
                <Badge badgeContent={notifications.length} color="error">
                    <NotificationsIcon />
                </Badge>
            </IconButton>
            <Menu
                id="menu-adminbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(anchorEl)}
                onClose={handleCloseMenu}
                transitionDuration={0}
            >
                <Box sx={{ width: '300px' }}>
                    <NotificationList notifications={notifications} />
                </Box>
            </Menu>
        </>
    );
}
