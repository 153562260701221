import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import PlayerLink from '@components/PlayerLink';
import ArenaLink from '@components/ArenaLink';
import UserContext from '@components/login/UserContext';
import { calcBg } from '@utils/Utils';

import './Round.css';

function GameTable({ game, className, user }) {
    const arena = game.arena;
    const { results, players } = game;

    // Note: Players are sorted by order in which they play, results are not sorted this way.

    return (
        <TableContainer component={Paper} className={className}>
            <Typography sx={{ flex: '1 1 100%' }} variant="h6" className="table-title" component="div">
                <ArenaLink arena={arena} />
            </Typography>
            <Table sx={{ minWidth: 300 }} size="small" aria-label="a dense table">
                <TableHead className="table-head">
                    <TableRow>
                        <TableCell sx={{ width: '25px' }}></TableCell>
                        <TableCell>Player Name</TableCell>
                        <TableCell>Score</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {players.map((player) => {
                        const result = results.find((r) => r.player === player._id);
                        return (
                            <TableRow
                                key={player._id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    bgcolor: calcBg(player, user),
                                }}
                            >
                                <TableCell component="th" scope="row" sx={{ color: 'success.main', fontWeight: 800 }}>
                                    {formatPosition(result?.position)}
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    <PlayerLink player={player} />
                                </TableCell>
                                <TableCell component="td" scope="row">
                                    {result?.score && result?.score?.toLocaleString()}
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default function Round({ games }) {
    const { user } = useContext(UserContext);
    sortGames(games, user);

    return (
        <Grid container spacing={2}>
            {games.map((game) => {
                return (
                    <Grid item xs={12} md={6} key={game.arena.id} className="game-item">
                        <GameTable game={game} user={user} className="game-table" />
                    </Grid>
                );
            })}
        </Grid>
    );
}

function formatPosition(pos) {
    if (pos === 0) return '1st';
    else if (pos === 1) return '2nd';
    else if (pos === 2) return '3rd';
    else if (pos === 3) return '4th';
    return null;
}

// Bring the users game to the top
function sortGames(games, user) {
    const userPlayerId = user?.player?._id;

    if (games) {
        const gameIndex = games.findIndex((g) => {
            return g.players?.some((p) => p._id === userPlayerId);
        });

        if (gameIndex > 0) {
            games.unshift(games.splice(gameIndex, 1)[0]);
        }
    }
}
