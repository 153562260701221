import React, { useState, useContext } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import UserContext from '@components/login/UserContext';
import { isAdmin } from '@utils/Utils';

function ListButton({ page, onClick, sx }) {
    return (
        <Button key={page.label} onClick={onClick} sx={sx}>
            <ListItem>
                {page.icon ? <ListItemIcon sx={{ minWidth: '44px' }}>{page.icon}</ListItemIcon> : null}
                {page.label}
            </ListItem>
        </Button>
    );
}

function Pages({ pages, onClick }) {
    const [subpageOpen, setSubpageOpen] = useState({});

    function handleMenuItemClick(page) {
        if (page.subpages) {
            setSubpageOpen({
                ...subpageOpen,
                [page.label]: !subpageOpen[page.label],
            });
        } else {
            onClick(page.path);
        }
    }
    return (
        <>
            {pages.map((page) => (
                <React.Fragment key={page.label}>
                    <ListButton
                        page={page}
                        onClick={() => handleMenuItemClick(page)}
                        sx={{ my: 2, display: 'block', margin: 0, width: '100%' }}
                    />
                    <Collapse in={subpageOpen[page.label]} timeout="auto">
                        <List component="div" disablePadding>
                            {page.subpages &&
                                page.subpages.map((sp) => (
                                    <ListButton
                                        key={sp.label}
                                        page={sp}
                                        onClick={() => onClick(sp.path)}
                                        sx={{ my: 2, pl: '20px', display: 'block', margin: 0, width: '100%' }}
                                    />
                                ))}
                        </List>
                    </Collapse>
                </React.Fragment>
            ))}
        </>
    );
}

export default function MobileMenu({ open, onClose, onClick, pages, adminPages }) {
    const { user } = useContext(UserContext);

    return (
        <Drawer anchor="left" open={open} onClose={onClose}>
            <>
                <List>
                    <Pages pages={pages} onClick={onClick} />
                    {isAdmin(user) ? <Pages pages={adminPages} onClick={onClick} /> : null}
                </List>
            </>
        </Drawer>
    );
}
