import { Player } from '@apis/Types';
import { showSnack } from '@utils/Events';

export default class SmackdownActionsProvider {
    selectedPlayers: Player[];
    playerActions;

    constructor(selectedPlayers, playerActions) {
        this.selectedPlayers = selectedPlayers || [];
        this.playerActions = playerActions;
    }

    provide() {
        return [
            {
                label: 'Belt Holder',
                onClick: handleBeltHolderToggle.bind(this, this.selectedPlayers, this.playerActions),
            },
        ];
    }
}

function handleBeltHolderToggle(selectedPlayers, playerActions) {
    const { updatePlayer, refreshPlayers } = playerActions;
    if (selectedPlayers.length !== 1) {
        showSnack('error', 'Please select a single player');
        return;
    }

    const id = selectedPlayers[0]._id;
    const player = selectedPlayers[0];
    const special = Object.assign({}, player.special, { beltHolder: !player.special?.beltHolder });
    updatePlayer(id, { special })
        .then(() => {
            showSnack('success', `Toggled belt holder to ${special.beltHolder} for ${player.name}`);
            refreshPlayers();
        })
        .catch((err) => {
            showSnack('error', `Failed to set belt holder for ${player.name}. ${err.error}`);
        });
}
