import { useState, useContext } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import UserContext from '@components/login/UserContext';

import './BadgeFilters.css';

export interface BadgeFiltersState {
    arena?: string;
    type?: string;
    status?: string;
}

interface BadgeFiltersProps {
    onClear: () => void;
    onSave: (state: BadgeFiltersState) => void;
    state: BadgeFiltersState;
    arenas: string[];
}

export default function BadgeFilters(props: BadgeFiltersProps) {
    const { onClear, onSave, state, arenas } = props;

    const [arena, setArena] = useState(state.arena || '');
    const [type, setType] = useState(state.type || '');
    const [status, setStatus] = useState(state.status || '');
    const { user } = useContext(UserContext);

    function handleChangeType(event) {
        setType(event.target.value);
    }

    function handleArenaChange(event) {
        setArena(event.target.value);
    }

    function handleStatusChange(event) {
        setStatus(event.target.value);
    }

    function handleClear() {
        setType('');
        setArena('');
        setStatus('');
        onClear();
    }

    function handleSave() {
        const state = { arena, type, status };

        // Remove empty values so we can see how many filters are applied just by counting the keys
        Object.keys(state).forEach((key) => {
            if (state[key] === '') {
                delete state[key];
            }
        });

        onSave(state);
    }

    return (
        <Stack className="badge-filter-controls">
            <FormControl variant="standard" fullWidth>
                <InputLabel id="type-label">Arena</InputLabel>
                <Select
                    labelId="arena-label"
                    id="arena"
                    className="badge-filter-input"
                    value={arena}
                    onChange={handleArenaChange}
                    label="Arena"
                >
                    <MenuItem value="">
                        <em>All</em>
                    </MenuItem>
                    {arenas.map((arena) => (
                        <MenuItem key={arena} value={arena}>
                            {arena}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl variant="standard" fullWidth className="badge-filter-input">
                <InputLabel id="type-label">Badge Type</InputLabel>
                <Select labelId="type-label" id="type" value={type} onChange={handleChangeType} label="Badge Type">
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    <MenuItem value={'score'}>Score</MenuItem>
                    <MenuItem value={'tournament'}>Tournament</MenuItem>
                    <MenuItem value={'season'}>Season</MenuItem>
                    <MenuItem value={'custom'}>Other</MenuItem>
                </Select>
            </FormControl>
            <ToggleButtonGroup
                className="badge-filter-input"
                color="secondary"
                value={''}
                exclusive
                fullWidth
                onChange={handleStatusChange}
                aria-label="Status"
            >
                <ToggleButton value={''} selected={status === ''} disabled={!user?.player}>
                    All
                </ToggleButton>
                <ToggleButton value="earned" selected={status === 'earned'} disabled={!user?.player}>
                    Earned
                </ToggleButton>
                <ToggleButton value="unearned" selected={status === 'unearned'} disabled={!user?.player}>
                    Unearned
                </ToggleButton>
            </ToggleButtonGroup>
            <Box className="badge-filter-button-container">
                <Button variant="outlined" className="badge-filter-button" onClick={handleClear}>
                    Clear
                </Button>
                <Button variant="contained" className="badge-filter-button" onClick={handleSave}>
                    Save
                </Button>
            </Box>
        </Stack>
    );
}
