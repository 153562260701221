import React, { useRef, useEffect, useContext } from 'react';
import Cropper from 'react-cropper';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import { asUploadButton } from '@rpldy/upload-button';
import Uploady, { useItemFinalizeListener, useItemProgressListener, withRequestPreSendUpdate } from '@rpldy/uploady';
import Container from '@mui/material/Container';

import UserContext from '@components/login/UserContext';
import { useImageUploadDest } from '@apis/Utils';
import { useUserActions } from '@apis/Users';
import { showSnack, hideDialog } from '@utils/Events';

import UploadPreview from '@rpldy/upload-preview';

import './UploadDialog.css';
import 'cropperjs/dist/cropper.css';

const UploadButton = asUploadButton((props) => {
    return (
        <Container align="center">
            <Button
                variant="contained"
                {...props}
                sx={{ marginLeft: 'auto', marginRight: 'auto', marginBottom: '15px' }}
            >
                Choose a file
            </Button>
        </Container>
    );
});

function Listeners() {
    const { setUser } = useContext(UserContext);
    const { whoami } = useUserActions();

    useItemProgressListener((data) => {
        if (data.completed === 100) {
            showSnack(
                'success',
                'Image uploaded successfully. Refresh the page if image does not update automatically'
            );
            setTimeout(() => {
                whoami().then((user) => {
                    if (user) setUser(user);
                });
            }, 2000); // Can probably lower this timeout or remove it if we switch to finalize callback
        }
    });

    // TODO figure out why i can use the finalize listener in the success case https://github.com/rpldy/react-uploady/issues/365
    useItemFinalizeListener((response) => {
        if (response.state === 'error') {
            const error = response.uploadResponse?.data?.error || 'Unhandled server exception';
            showSnack('error', `Error uploading image. ${error}`);
        }
    });

    return null;
}

const ItemPreviewWithCrop = withRequestPreSendUpdate(
    ({ url, updateRequest, previewMethods, requestData, itemPreviewRef }) => {
        const cropperRef = useRef(null);

        useEffect(() => {
            itemPreviewRef.current = {
                onCancel,
                onUpdate,
            };
        }, [requestData, previewMethods, updateRequest]);

        function onCancel() {
            updateRequest && updateRequest(false);
            if (previewMethods.current?.clear) {
                previewMethods.current.clear();
            }
        }

        function onUpdate() {
            const cropper = cropperRef.current.cropper;
            cropper.getCroppedCanvas().toBlob((blob) => {
                requestData.items[0].file = blob;
                updateRequest({ items: requestData.items });
            }, requestData.items[0].file.type);
        }

        return (
            <Box sx={{ height: '100%', width: '100%' }}>
                <Cropper
                    initialAspectRatio={1}
                    aspectRatio={1}
                    autoCrop={true}
                    background={false}
                    modal={false}
                    viewMode={1}
                    zoomable={false}
                    src={url}
                    ref={cropperRef}
                />
            </Box>
        );
    }
);

export default function UploadDialog() {
    const previewMethodsRef = useRef();
    const itemPreviewRef = useRef();

    function handleSave() {
        itemPreviewRef?.current?.onUpdate();
        hideDialog();
    }

    function handleCancel() {
        itemPreviewRef?.current?.onCancel();
        hideDialog();
    }

    const imageUploadDest = useImageUploadDest();

    return (
        <>
            <DialogTitle id="alert-dialog-title">Upload a player picture</DialogTitle>
            <DialogContent sx={{ width: '280px' }}>
                <Uploady destination={imageUploadDest}>
                    <Listeners />
                    <UploadButton />
                    <UploadPreview
                        PreviewComponent={ItemPreviewWithCrop}
                        previewComponentProps={{ previewMethods: previewMethodsRef, itemPreviewRef }}
                        previewMethodsRef={previewMethodsRef}
                    />
                </Uploady>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel}>Cancel</Button>
                <Button onClick={handleSave} autoFocus={true}>
                    Save
                </Button>
            </DialogActions>
        </>
    );
}
