import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { useParams, useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import Lock from '@mui/icons-material/Lock';
import { showSnack } from '@utils/Events';
import { useUserActions } from '@apis/Users';

const validationSchema = Yup.object().shape({
    password: Yup.string().required('Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), null], "Passwords don't match")
        .required('Confirmation password is required'),
});

export default function PassowrdRest() {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const { token } = useParams();
    const navigate = useNavigate();
    const { reset } = useUserActions();

    function handleKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit)();
        }
    }

    function onSubmit({ password, confirmPassword }) {
        reset(token, password, confirmPassword)
            .then(() => {
                showSnack('success', `Successfully changed password`);
                navigate('/');
            })
            .catch((e) => {
                showSnack('error', e.error || 'An unknown error occured');
            });
    }

    return (
        <Container>
            <Stack onKeyPress={handleKeyPress} maxWidth="320px">
                <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                    <TextField
                        id="password"
                        label="Password"
                        type="password"
                        {...register('password')}
                        error={!!errors.password}
                        helperText={errors?.password?.message}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                </FormControl>
                <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                    <TextField
                        id="confirmPassword"
                        label="Confirm Password"
                        type="password"
                        {...register('confirmPassword')}
                        error={!!errors.confirmPassword}
                        helperText={errors?.confirmPassword?.message}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Lock />
                                </InputAdornment>
                            ),
                        }}
                        variant="standard"
                    />
                </FormControl>
                <Button variant="contained" sx={{ marginTop: '15px' }} onClick={handleSubmit(onSubmit)}>
                    Reset
                </Button>
            </Stack>
        </Container>
    );
}
