import { createContext } from 'react';

interface TokenContextType {
    token: string | null;
    setToken: (string) => void;
}

export default createContext<TokenContextType>({
    token: null,
    setToken: (arg: string) => {},
});
