import { Player } from '@apis/Types';
import { showSnack } from '@utils/Events';

export default class VirusActionsProvider {
    selectedPlayers: Player[];
    playerActions;

    constructor(selectedPlayers, playerActions) {
        this.selectedPlayers = selectedPlayers || [];
        this.playerActions = playerActions;
    }

    provide() {
        return [
            {
                label: 'Patient Zero',
                onClick: handlePatientZero.bind(this, this.selectedPlayers, this.playerActions),
            },
            {
                label: 'Infect',
                onClick: handleInfectOrDisinfect.bind(this, true, this.selectedPlayers, this.playerActions),
            },
            {
                label: 'Disinfect',
                onClick: handleInfectOrDisinfect.bind(this, false, this.selectedPlayers, this.playerActions),
            },
            {
                label: 'Reset Special',
                onClick: handleResetSpecial.bind(this, this.selectedPlayers, this.playerActions),
            },
        ];
    }
}

function handlePatientZero(selectedPlayers, playerActions) {
    const { updatePlayer, refreshPlayers } = playerActions;
    if (!validateSelected(selectedPlayers)) return;
    if (selectedPlayers.length > 1) {
        showSnack('error', 'Bulk patient zeroing not yet implemented');
        return;
    }

    const id = selectedPlayers[0]._id;
    const player = selectedPlayers[0];
    const special = Object.assign({}, player.special, { patientZero: !player.special?.patientZero });
    updatePlayer(id, { special })
        .then(() => {
            showSnack('success', `Toggled patient zero to ${special.patientZero} for ${player.name}`);
            refreshPlayers();
        })
        .catch((err) => {
            showSnack('error', `Failed to set patient zero for ${player.name}. ${err.error}`);
        });
}

function handleResetSpecial(selectedPlayers, playerActions) {
    const { updatePlayers, refreshPlayers } = playerActions;

    if (!validateSelected(selectedPlayers)) return;

    const data = selectedPlayers.map((player) => {
        return {
            _id: player._id,
            special: null,
        };
    });

    updatePlayers(data)
        .then(() => {
            showSnack('success', `Reset special for ${data.length} players.`);
            refreshPlayers();
        })
        .catch((err) => {
            showSnack('error', `Failed to reset special for players. ${err.error}`);
        });
}

function handleInfectOrDisinfect(infected, selectedPlayers, playerActions) {
    const { updatePlayers, refreshPlayers } = playerActions;

    if (!validateSelected(selectedPlayers)) return;

    const data = selectedPlayers.map((player) => {
        return {
            _id: player._id,
            special: Object.assign({}, player.special, { infected }),
        };
    });

    updatePlayers(data)
        .then(() => {
            showSnack('success', `Updated infected state for ${data.length} players.`);
            refreshPlayers();
        })
        .catch((err) => {
            showSnack('error', `Failed to update players. ${err.error}`);
        });
}

function validateSelected(selectedPlayers) {
    if (selectedPlayers.length === 0) {
        showSnack('error', 'Please select a player');
        return false;
    }

    return true;
}
