import React, { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import EditIcon from '@mui/icons-material/Edit';

import UploadDialog from '@components/profile/UploadDialog';
import { showDialog, hideDialog } from '@utils/Events';
import UserContext from '@components/login/UserContext';
import PlayerAvatar from '@components/PlayerAvatar';

import './EditableAvatar.css';

export default function EditableAvatar() {
    const { user } = useContext(UserContext);

    function handleClick() {
        // Hide the claim player dialog
        hideDialog();
        setTimeout(() => {
            showDialog(<UploadDialog />);
        }, 500);
    }

    return (
        <>
            <Box className="edit-wrapper" onClick={handleClick}>
                {user?.player ? (
                    <PlayerAvatar
                        player={user.player}
                        variant="rounded"
                        className="upload-button"
                        hideBadgeCount
                        sx={{ height: 96, width: 96 }}
                    />
                ) : (
                    <Avatar
                        src={user?.picture || null}
                        variant="rounded"
                        className="upload-button"
                        sx={{ cursor: 'pointer', height: '96px', width: '96px' }}
                    />
                )}

                <EditIcon className="edit-icon" />
            </Box>
        </>
    );
}
