import { useContext } from 'react';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login';

import { showSnack } from '@utils/Events';
import { showDialog } from '@utils/Events';
import UserContext from '@components/login/UserContext';
import TokenContext from '@components/login/TokenContext';
import ClaimPlayer from '@components/profile/ClaimPlayer';
import { useUserActions } from '@apis/Users';

import './LoginDialog.css';

const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
const appId = process.env.REACT_APP_FACEBOOK_APP_ID;

export default function SocialLogins({ signup, handleClose }) {
    const { setUser } = useContext(UserContext);
    const { setToken } = useContext(TokenContext);
    const { validateGoogleAuth, validateFacebookAuth } = useUserActions();

    function onGoogleSuccess(res) {
        const access_token = res.accessToken;
        validateGoogleAuth(access_token)
            .then(({ user, token, created }) => {
                setUser(user);
                setToken(token);
                if (created) {
                    showSnack('success', `Successfully registered ${user.name}.`);
                    showDialog(<ClaimPlayer />);
                }

                handleClose();
            })
            .catch((err) => {
                showSnack('error', err.error);
            });
    }

    function onGoogleFailure(e) {
        console.log(e);
        showSnack('error', 'Error authenticating');
    }

    function onFacebookResponse(res) {
        const access_token = res.accessToken;
        validateFacebookAuth(access_token)
            .then(({ user, token, created }) => {
                setUser(user);
                setToken(token);
                if (created) {
                    showSnack('success', `Successfully registered ${user.name}.`);
                    showDialog(<ClaimPlayer />);
                }
                handleClose();
            })
            .catch((err) => {
                showSnack('error', err.error);
            });
    }

    return (
        <>
            <GoogleLogin
                clientId={clientId}
                buttonText={signup ? 'Signup with Google' : 'Login with Google'}
                theme="dark"
                onSuccess={onGoogleSuccess}
                onFailure={onGoogleFailure}
                cookiePolicy="single_host_origin"
                className="google-button"
            />
            <div className="facebook-button">
                <FacebookLogin
                    appId={appId}
                    textButton={signup ? 'Signup with Facebook' : 'Login with Facebook'}
                    size="small"
                    icon="fa-facebook"
                    fields="name,email,picture"
                    callback={onFacebookResponse}
                />
            </div>
        </>
    );
}
