import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { deepOrange } from '@mui/material/colors';
import Link from '@mui/material/Link';

// Icons
import FacebookIcon from '@mui/icons-material/Facebook';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';

import './Footer.css';

export default function Footer() {
    return (
        <div className="footer">
            <Divider />
            <Typography align="center" className="copyright">
                <a href="https://www.facebook.com/groups/915058475204665">
                    <FacebookIcon className="footerIcon" sx={{ color: deepOrange[500] }} fontSize="large" />
                </a>
                <a href="https://sites.google.com/site/trianglepinballplayers">
                    <GoogleIcon className="footerIcon" sx={{ color: deepOrange[500] }} fontSize="large" />
                </a>
                <a href="mailto:admin@tpleague.fun">
                    <EmailIcon className="footerIcon" sx={{ color: deepOrange[500] }} fontSize="large" />
                </a>
                <Typography variant="caption" display="block" gutterBottom>
                    Copyright © 2022 Triangle Pinball Players
                </Typography>
                <Typography variant="caption" display="block" gutterBottom>
                    <Link component={RouterLink} to="/attributions">
                        Attributions
                    </Link>
                </Typography>
            </Typography>
            ;
        </div>
    );
}
