import React, { useState } from 'react';

import Typography from '@mui/material/Typography';
import LinearProgress from '@mui/material/LinearProgress';
import Container from '@mui/material/Container';
import Pagination from '@mui/material/Pagination';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';

import BadgeList from '@components/BadgeList';

import './BadgeCard.css';

const PAGE_SIZE = 10;

// Get the first half of the page (ie 0-5 on page 1)
function firstHalf(badges, page) {
    page = page - 1;

    if (!badges) return null;

    return badges.filter((el, index) => {
        return index >= page * PAGE_SIZE && index < page * PAGE_SIZE + PAGE_SIZE / 2;
    });
}

// Get the second half of the page (ie 5-10 on page 1)
function secondHalf(badges, page) {
    page = page - 1;

    if (!badges) return null;

    return badges.filter((el, index) => {
        return index >= page * PAGE_SIZE + PAGE_SIZE / 2 && index < page * PAGE_SIZE + PAGE_SIZE;
    });
}

function TitleContent({ badges }) {
    const earned = badges && badges.filter((b) => !!b.earnedOn).length;

    if (!badges) return <Skeleton variant="text" />;

    return (
        <Container sx={{ display: 'flex' }}>
            <Typography variant="h6" sx={{ marginRight: '15px' }}>
                Badges
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', width: '80%', marginLeft: 'auto' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" value={(earned / badges.length) * 100} color="secondary" />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">
                        {`${earned}/${badges.length}`}
                    </Typography>
                </Box>
            </Box>
        </Container>
    );
}

function PaginationContent({ badges, onChange }) {
    if (!badges) return null;

    return (
        <Pagination
            count={Math.ceil(badges.length / PAGE_SIZE)}
            color="primary"
            onChange={onChange}
            className="pagination"
        />
    );
}

export default function BadgeCard({ badges }) {
    const [page, setPage] = useState(1);

    const handleChange = (event, value) => {
        setPage(value);
    };

    return (
        <Container sx={{ padding: '20px' }}>
            <TitleContent badges={badges} />
            <div className="badge-container">
                <Grid container spacing={2}>
                    <Grid item sm={6} sx={{ width: '100%' }}>
                        <BadgeList badges={firstHalf(badges, page)} stencils={PAGE_SIZE / 2} />
                    </Grid>
                    <Grid item sm={6} sx={{ width: '100%' }}>
                        <BadgeList badges={secondHalf(badges, page)} stencils={PAGE_SIZE / 2} />
                    </Grid>
                </Grid>
                <PaginationContent badges={badges} onChange={handleChange} />
            </div>
        </Container>
    );
}
