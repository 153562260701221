import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { CircularProgress, Box } from '@mui/material';

import BadgeIcon from '@components/BadgeIcon';
import PlayerAvatar from '@components/PlayerAvatar';
import { usePlayer } from '@apis/Players';

import './BadgeCard.css';

function CardContents(props) {
    const { player } = props;
    const badges = (player && player.badges) || [];

    if (!player) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: 100,
                }}
            >
                <CircularProgress />
            </Box>
        );
    } else if (badges.length === 0) {
        return <Typography align="center">No Badges</Typography>;
    } else {
        return (
            <div>
                <CardHeader
                    avatar={<PlayerAvatar player={player} hideBadgeCount />}
                    title={player.name}
                    subheader={badges.length === 1 ? '1 badge' : `${badges.length} badges`}
                />
                <Divider />
                <CardContent sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {badges.map((badgeObj) => (
                        <div className="badgeContainer" key={badgeObj.badge._id}>
                            <BadgeIcon useTooltip badge={badgeObj.badge} key={badgeObj.badge._id} />
                        </div>
                    ))}
                </CardContent>
            </div>
        );
    }
}

interface BadgeCardProps {
    playerId: string;
}

export default function BadgeCard(props: BadgeCardProps) {
    const { player } = usePlayer(props.playerId);

    return (
        <Card sx={{ maxWidth: 500, minWidth: 400, minHeight: 100 }}>
            <CardContents player={player} />
        </Card>
    );
}
