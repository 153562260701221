import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Special from '@components/Special';

export default function PlayerLink({ player }) {
    const { _id, name } = player;

    return (
        <>
            <Link component={RouterLink} to={`/player/${_id}`} sx={{ marginRight: '10px' }}>
                {name}
            </Link>
            <Special player={player} />
        </>
    );
}
