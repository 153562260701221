import { Link as RouterLink } from 'react-router-dom';

import Link from '@mui/material/Link';
export default function ArenaLink(props) {
    const { _id, name } = props.arena;

    return (
        <Link component={RouterLink} to={`/arena/${_id}`}>
            {name}
        </Link>
    );
}
