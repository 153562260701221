import { ReactNode } from 'react';
import Paper from '@mui/material/Paper';
import { SxProps, Theme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

type ResponsivePaperProps = {
    sx?: SxProps<Theme>;
    children: ReactNode | ReactNode[];
};

export default function ResponsivePaper(props: ResponsivePaperProps) {
    const { children, sx } = props;

    const theme = useTheme();
    const elevate = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Paper elevation={elevate ? 3 : 0} sx={sx}>
            {children}
        </Paper>
    );
}
