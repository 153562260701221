import { User } from '@apis/Types';
import { useFetchWrapper } from '@apis/Utils';

interface LoginResponse {
    user: User;
    token: string;
    created?: boolean;
}

export function useUserActions() {
    const { get, post } = useFetchWrapper();

    function validateGoogleAuth(access_token: string): Promise<LoginResponse> {
        return post(`login/gauth`, { access_token });
    }

    function validateFacebookAuth(access_token: string): Promise<LoginResponse> {
        return post(`login/fauth`, { access_token });
    }

    function registerUser(data) {
        return post('register', data);
    }

    function login(username: string, password: string): Promise<LoginResponse> {
        const encoded = window.btoa(`${username}:${password}`);
        return get('login', null, { Authorization: `Basic ${encoded}` });
    }

    // This function is used outside of the token provider so we can't rely on the authorization added inside the fetch wrapper
    function whoami(token): Promise<User> {
        return get('whoami', null, { Authorization: `Bearer ${token}` });
    }

    function clearNotifications(): Promise<User> {
        return post('user/clearNotifications');
    }

    function forgot(data): Promise<void> {
        return post('forgot', data);
    }

    function reset(token: string, password: string, confirmPassword: string): Promise<void> {
        return post(`reset/${token}`, { password, confirmPassword });
    }

    return { validateFacebookAuth, validateGoogleAuth, registerUser, login, whoami, clearNotifications, forgot, reset };
}
