import { useEffect, useState, useContext } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FilterListIcon from '@mui/icons-material/FilterList';
import Drawer from '@mui/material/Drawer';
import Badge from '@mui/material/Badge';
import IconButton from '@mui/material/IconButton';
import Popover from '@mui/material/Popover';
import { Typography } from '@mui/material';

import BadgeList from '@components/BadgeList';
import BadgeFilters, { BadgeFiltersState } from '@components/BadgeFilters';
import { useBadges } from '@apis/Badges';
import UserContext from '@components/login/UserContext';
import { Badge as BadgeType, EarnedBadge } from '@apis/Types';
import { User, Player } from '@apis/Types';
import { useSessionStorage, capitalize } from '@utils/Utils';

import './BadgePage.css';

function applyFilters(badges: BadgeType[], filters: BadgeFiltersState, user: User): EarnedBadge[] {
    let filteredBadges = [].concat(badges);
    const player = user?.player as Player;

    if (filters.arena) {
        filteredBadges = filteredBadges.filter((badge) => badge.arenaName === filters.arena);
    }

    if (filters.type) {
        filteredBadges = filteredBadges.filter((badge) => badge.category === filters.type);
    }

    if (filters.status && player) {
        filteredBadges = filteredBadges.filter((badge) => {
            const earned = player.badges.some((playerBadge) => playerBadge.badge === badge._id);
            return filters.status === 'earned' ? earned : !earned;
        });
    }

    return filteredBadges;
}

// This function exists because badge.category has 'custom' as a type but doesn't make sense to end user
// also to capitalize the label
// We could probably return labels along with the values but this is good enough for now
function getFilterLabel(key: string, value: string) {
    if (key === 'type' && value === 'custom') return `${capitalize(key)}: Other`;
    return `${capitalize(key)} : ${capitalize(value)}`;
}

function getStatusLabel(count: number) {
    if (count === 0) return '';
    if (count === 1) return '1 badge';
    return `${count} badges`;
}

export default function BadgePage() {
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [filters, setFilters] = useSessionStorage<BadgeFiltersState>('badgeFilters', {});
    const [filteredBadges, setFilteredBadges] = useState<EarnedBadge[]>([]);
    const [arenaNames, setArenaNames] = useState<string[]>([]);
    const { badges } = useBadges();
    const { user } = useContext(UserContext);

    function handleOpen(event) {
        setOpen(true);
        setAnchorEl(event.currentTarget);
    }

    function handleClose() {
        setOpen(false);
        setAnchorEl(null);
    }

    function handleClear() {
        setFilters({});
        handleClose();
    }

    function handleSave(state) {
        setFilters(state);
        handleClose();
    }

    function handleDelete(key) {
        const newState = { ...filters };
        delete newState[key];
        setFilters(newState);
    }

    useEffect(() => {
        let newBadges: EarnedBadge[];
        if (!badges) return;

        if (filters) {
            newBadges = applyFilters(badges, filters, user);
        } else {
            newBadges = [].concat(badges);
        }

        // Merge in players earned on date
        if (newBadges && user?.player) {
            const player = user?.player as Player;
            player?.badges.forEach((playerBadge) => {
                const badge = newBadges.find((badge) => badge._id === playerBadge.badge);
                if (badge) badge.earnedOn = playerBadge.earnedOn;
            });
        }

        setFilteredBadges(newBadges);
    }, [badges, filters, user]);

    useEffect(() => {
        if (badges) {
            const newArenaNames = badges.reduce((acc, badge) => {
                if (badge.arenaName) acc.add(badge.arenaName);
                return acc;
            }, new Set<string>());

            setArenaNames(Array.from(newArenaNames).sort());
        }
    }, [badges]);

    return (
        <Box className="badge-page">
            <Box className="badge-filter-container">
                <Box className="badge-count-container">
                    <Typography className="badge-count" variant="subtitle2">
                        {getStatusLabel(filteredBadges?.length || 0)}
                    </Typography>
                </Box>
                <Box className="badge-filter-icon">
                    <IconButton aria-label="filter" color="primary" component="button">
                        <Badge
                            onClick={handleOpen}
                            badgeContent={Object.keys(filters).length}
                            invisible={Object.keys(filters).length === 0}
                            color="secondary"
                        >
                            <FilterListIcon />
                        </Badge>
                    </IconButton>
                </Box>
            </Box>
            {filters &&
                Object.keys(filters).map((key) => (
                    <Chip
                        key={key}
                        sx={{
                            maxWidth: { xs: '150px', sm: '300px' },
                        }}
                        className="badge-filter-chip"
                        label={getFilterLabel(key, filters[key])}
                        variant="outlined"
                        onDelete={() => handleDelete(key)}
                    />
                ))}
            <Drawer
                anchor="bottom"
                open={open}
                onClose={handleClose}
                sx={{
                    display: { xs: 'block', md: 'none' },
                }}
            >
                <Box className="badge-filter-contents">
                    <BadgeFilters onClear={handleClear} onSave={handleSave} arenas={arenaNames} state={filters} />
                </Box>
            </Drawer>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                sx={{
                    display: { xs: 'none', md: 'block' },
                }}
            >
                <Box className="badge-filter-contents" sx={{ width: '400px' }}>
                    <BadgeFilters onClear={handleClear} onSave={handleSave} arenas={arenaNames} state={filters} />
                </Box>
            </Popover>
            <BadgeList badges={filteredBadges} noGrayscale stencils={10} />
        </Box>
    );
}
