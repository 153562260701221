import React, { useEffect, useState } from 'react';
import Dialog from '@mui/material/Dialog';

export default function CustomDialog() {
    const [open, setOpen] = useState(false);
    const [content, setContent] = useState(null);

    function handleClose() {
        setOpen(false);
    }

    function showDialog({ detail }) {
        setContent(detail.content);

        setOpen(true);
    }

    useEffect(() => {
        document.addEventListener('showdialog', showDialog);
        document.addEventListener('hidedialog', handleClose);

        return () => {
            document.removeEventListener('showdialog', showDialog);
            document.removeEventListener('hidedialog', handleClose);
        };
    }, []);

    return (
        <Dialog onClose={handleClose} open={open}>
            {content}
        </Dialog>
    );
}
