import React, { useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import PlayerLink from '@components/PlayerLink';
import UserContext from '@components/login/UserContext';
import { calcBg, shorten } from '@utils/Utils';
import { usePastSeasons } from '@apis/Seasons';
import { useArenas, useArenaStats } from '@apis/Arenas';

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartOptions,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

import './ArenaStats.css';

const NUM_BARS = 19;
const FIRST_PAGE = 15;

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options: ChartOptions = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Score Distribution',
        },
    },
};

function ArenaStatsBody({ results }) {
    const [showMore, setShowMore] = useState(false);
    const { user } = useContext(UserContext);

    if (!results) return null;
    if (results.length === 0)
        return (
            <Typography align="center" sx={{ padding: '20px' }}>
                No Results
            </Typography>
        );

    const data = calculateData(results);

    return (
        <>
            <Typography variant="h5">
                <img src="/images/award.png" className="trophy-image" />
                Grand Champion
                <img src="/images/award.png" className="trophy-image" />
            </Typography>
            <Typography variant="h6" sx={{ bgcolor: calcBg(results[0].player, user) }}>
                <span>
                    <PlayerLink player={results[0].player}></PlayerLink>
                </span>
                <span className="gc-score">{results[0].score && results[0].score.toLocaleString()}</span>
            </Typography>
            <Container maxWidth="md" sx={{ marginLeft: 'auto !important', marginRight: 'auto !important' }}>
                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    {(showMore ? results : results.slice(0, FIRST_PAGE)).map((result, index) =>
                        index === 0 ? (
                            ''
                        ) : (
                            <React.Fragment key={result._id}>
                                <ListItem
                                    sx={{
                                        bgcolor: calcBg(result.player, user),
                                    }}
                                >
                                    {index}.{' '}
                                    <span>
                                        &nbsp;
                                        <PlayerLink player={result.player} />
                                    </span>
                                    <span className="score">{result.score && result.score.toLocaleString()}</span>
                                </ListItem>
                                <Divider component="li" />
                            </React.Fragment>
                        )
                    )}
                </List>
                {results && results.length > FIRST_PAGE ? (
                    <Button color="secondary" sx={{ marginBottom: '30px' }} onClick={() => setShowMore(!showMore)}>
                        {showMore ? 'Show Less' : 'Show More'}
                    </Button>
                ) : (
                    ''
                )}
                <Bar options={options} data={data} />
            </Container>
        </>
    );
}

export default function ArenaStats() {
    const { id } = useParams();
    const [season, setSeason] = useState('current');
    const [enabled, setEnabled] = useState(false);

    const { seasons } = usePastSeasons(enabled);
    const { results, arena } = useArenaStats(id, season === 'all' ? null : season);

    function handleSeasonChange(event) {
        const newSeason = event.target.value;
        setSeason(newSeason);
    }

    function handleSeasonsOpen() {
        setEnabled(true);
    }

    if (!arena) return null;

    return (
        <Paper elevation={3} sx={{ padding: '20px' }}>
            <Stack spacing={1} alignItems="center" sx={{ position: 'relative' }}>
                <Box sx={{ position: { md: 'absolute' }, top: 0, right: 0 }}>
                    <FormControl sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="season-select">Season</InputLabel>
                        <Select
                            labelId="season-select"
                            id="season-select"
                            onOpen={handleSeasonsOpen}
                            value={season}
                            label="Season"
                            onChange={handleSeasonChange}
                        >
                            <MenuItem value="current">Current Season</MenuItem>
                            <MenuItem value="all">All Time</MenuItem>
                            {seasons && seasons.length > 0 ? <ListSubheader>Past Seasons</ListSubheader> : ''}
                            {seasons &&
                                seasons.map((s) => (
                                    <MenuItem key={s._id} value={s._id}>
                                        {s.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Box>
                <Box>
                    <ArenaSelector value={arena}></ArenaSelector>
                </Box>
                <ArenaStatsBody results={results} />
            </Stack>
        </Paper>
    );
}

function ArenaSelector({ value }) {
    const navigate = useNavigate();

    const [loadArenas, setLoadArenas] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const { arenas } = useArenas(loadArenas);

    function handleArenaClick(event) {
        setAnchorEl(event.currentTarget);
        if (!arenas) {
            setLoadArenas(true);
        }
    }

    function handleArenaChange(id) {
        navigate(`/arena/${id}`);
        handleClose();
    }

    function handleClose() {
        setAnchorEl(null);
    }

    return (
        <Box>
            <Typography onClick={handleArenaClick} variant="h4" sx={{ cursor: 'pointer', width: 'fit-content' }}>
                {value.name} <ArrowDropDownIcon fontSize="inherit" sx={{ verticalAlign: 'middle' }} />
            </Typography>
            <Menu id="arena-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                {arenas &&
                    arenas.map((a) => (
                        <MenuItem
                            key={a._id}
                            value={a._id}
                            onClick={() => {
                                handleArenaChange(a._id);
                            }}
                        >
                            {a.name}
                        </MenuItem>
                    ))}
            </Menu>
        </Box>
    );
}

function calculateData(results) {
    const step = results[0].score / NUM_BARS;
    const labels = [];
    const data = [];

    for (let i = 0; i <= NUM_BARS; i++) {
        labels.push(`${shorten(step * i)}-${shorten(step * (i + 1))}`);
    }

    results.forEach((r) => {
        const bucket = Math.floor(r.score / step);
        data[bucket] = data[bucket] || 0;
        data[bucket]++;
    });

    const chartData = {
        labels,
        datasets: [
            {
                label: 'Scores',
                data,
                backgroundColor: 'rgb(0, 81, 181, .7)',
            },
        ],
    };

    return chartData;
}
