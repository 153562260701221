import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Button from '@mui/material/Button';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrow from '@mui/icons-material/PlayArrow';
import Sync from '@mui/icons-material/Sync';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import ProgressBlob from '@components/ProgressBlob';
import ImportStatus from '@components/ImportStatus';
import { showSnack } from '@utils/Events';
import { useImportJob, useImportActions } from '@apis/Imports';
import Typography from '@mui/material/Typography';

const TIMEOUT = 10000;

export default function Import() {
    const [loading, setLoading] = useState(true);

    const { id } = useParams();
    const { job, error, refresh } = useImportJob(id);
    const { createImport, stopImport } = useImportActions();

    useEffect(() => {
        if (job) {
            setLoading(false);
        }

        if (job?.jobStatus === 'started') {
            setTimeout(() => refresh(), TIMEOUT); // Recursively call this if its in progress
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [job]);

    useEffect(() => {
        if (error) {
            setLoading(false);
            showSnack('error', error.error);
        }
    }, [error]);

    if (loading) {
        return <CircularProgress sx={{ marginLeft: 'auto', marginRight: 'auto' }} />;
    }

    // Button Handlers
    function handleSyncClick() {
        createImport('current', id, false, true)
            .then(() => {
                showSnack('success', 'Started an immediate one time sync.');
                refresh();
            })
            .catch((err) => {
                showSnack('error', err.error);
            });
    }

    function handleStartClick() {
        createImport('current', id)
            .then(() => {
                showSnack('success', 'Started syncing.');
                refresh();
            })
            .catch((err) => {
                showSnack('error', err.error);
            });
    }

    function handleStopClick() {
        stopImport(id)
            .then(() => {
                showSnack('success', 'Stopped job');
                refresh();
            })
            .catch((err) => {
                showSnack('error', err.error);
            });
    }

    const complete = job?.jobStatus === 'completed';

    return (
        <Container maxWidth="xl">
            <Box display="flex" flexWrap="wrap">
                <Typography sx={{ flexGrow: 1, marginTop: 'auto', marginBottom: 'auto', marginRight: '5px' }}>
                    <ProgressBlob status={job?.jobStatus} />
                    {job?.jobStatus?.toUpperCase()}
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ marginRight: '10px' }}
                        startIcon={<Sync />}
                        onClick={handleSyncClick}
                    >
                        Sync
                    </Button>
                    <Button
                        variant="contained"
                        color="success"
                        sx={{ marginRight: '10px' }}
                        startIcon={<PlayArrow />}
                        disabled={!complete}
                        onClick={handleStartClick}
                    >
                        Start
                    </Button>
                    <Button
                        variant="contained"
                        color="error"
                        startIcon={<StopIcon />}
                        disabled={complete}
                        onClick={handleStopClick}
                    >
                        Stop
                    </Button>
                </Box>
            </Box>
            <Box sx={{ marginTop: '10px' }}>
                <ImportStatus status={job?.importStatus} />
            </Box>
        </Container>
    );
}
