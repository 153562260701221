import { useState } from 'react';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Input from '@mui/material/Input';
import FormControl from '@mui/material/FormControl';
import InputAdornment from '@mui/material/InputAdornment';
import InputLabel from '@mui/material/InputLabel';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Email from '@mui/icons-material/Email';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import { useUserActions } from '@apis/Users';
import { showSnack } from '@utils/Events';

const validationSchema = Yup.object().shape({
    email: Yup.string().email().required('Email is required'),
});

export default function ForgotLoginForm({ handleClose }) {
    const {
        handleSubmit,
        register,
        formState: { errors },
    } = useForm({ resolver: yupResolver(validationSchema) });

    const [serverError, setServerError] = useState(null);
    const { forgot } = useUserActions();

    function onSubmit(data) {
        setServerError(null);

        // Server will lookup email and validate
        forgot(data)
            .then(() => {
                handleClose();
                showSnack(
                    'success',
                    "Email sent. Check your spam folder if you don't see the email and please follow the instructions in the email to reset your password."
                );
            })
            .catch((e) => {
                setServerError(e.error || 'An unknown error occured');
            });
    }

    function getError() {
        const keys = Object.keys(errors);
        if (keys && keys.length > 0) {
            return errors[keys[0]]?.message;
        }

        if (serverError) return serverError;
    }

    function handleKeyPress(e) {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleSubmit(onSubmit)();
        }
    }

    return (
        <>
            <DialogContent sx={{ width: '290px' }}>
                <Stack onKeyPress={handleKeyPress}>
                    {getError() ? (
                        <Alert severity="error" sx={{ marginBottom: '10px' }}>
                            {getError()}
                        </Alert>
                    ) : (
                        ''
                    )}

                    <FormControl variant="standard" sx={{ marginTop: '30px' }}>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                            id="email"
                            inputProps={{
                                autoCapitalize: 'none',
                            }}
                            {...register('email')}
                            error={!!errors.email}
                            startAdornment={
                                <InputAdornment position="start">
                                    <Email />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </Stack>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button onClick={handleSubmit(onSubmit)}>Send</Button>
            </DialogActions>
        </>
    );
}
