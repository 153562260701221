import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './client/App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Roboto font
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

serviceWorkerRegistration.register();

const root = createRoot(document.getElementById('root'));

root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
