import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import BadgeIcon from '@components/BadgeIcon';
import Stack from '@mui/material/Stack';
import Skeleton from '@mui/material/Skeleton';
import Box from '@mui/material/Box';
import { EarnedBadge } from '@apis/Types';

import './BadgeList.css';

function BadgeContents({ displayName, earnedOn }) {
    return (
        <>
            <span className="badge-name">{displayName}</span>
            {earnedOn ? (
                <Chip
                    label={`${new Date(earnedOn).toLocaleDateString('en-US')}`}
                    color="success"
                    size="small"
                    sx={{ float: 'right' }}
                />
            ) : (
                ''
            )}
        </>
    );
}

interface BadgeListProps {
    badges: EarnedBadge[];
    stencils: number;
    noGrayscale: boolean;
}

export default function BadgeList(props: BadgeListProps) {
    const { badges, stencils, noGrayscale } = props;
    if (!badges) {
        return (
            <Stack spacing={2}>
                {Array(stencils)
                    .fill(null)
                    .map((a, index) => (
                        <Box key={index}>
                            <Skeleton variant="circular" width={40} height={40} sx={{ display: 'inline-block' }} />
                            <Skeleton
                                variant="text"
                                width={210}
                                sx={{
                                    display: 'inline-block',
                                    verticalAlign: 'top',
                                    marginTop: '10px',
                                    marginLeft: '10px',
                                }}
                            />
                            <Skeleton variant="text" sx={{ maxWidth: '360px' }} />
                        </Box>
                    ))}
            </Stack>
        );
    }
    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
            {badges &&
                badges.map((badge) => (
                    <React.Fragment key={badge._id}>
                        <Divider variant="inset" component="li" />
                        <ListItem alignItems="flex-start">
                            <ListItemAvatar>
                                <BadgeIcon
                                    badge={badge}
                                    className={badge.earnedOn || noGrayscale ? '' : 'unearned-badge'}
                                />
                            </ListItemAvatar>
                            <ListItemText
                                primary={<BadgeContents displayName={badge.display} earnedOn={badge.earnedOn} />}
                                secondary={
                                    <Typography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="text.primary"
                                    >
                                        {badge.description}
                                    </Typography>
                                }
                            />
                        </ListItem>
                    </React.Fragment>
                ))}
        </List>
    );
}
