import React, { useContext } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import PlayerAvatar from '@components/PlayerAvatar';
import PlayerLink from '@components/PlayerLink';
import UserContext from '@components/login/UserContext';
import { calcBg } from '@utils/Utils';

import './OverallTable.css';

export default function OverallTable({ standings, season, className }) {
    const { user } = useContext(UserContext);

    const color = 'white';
    const bgcolor = 'primary.main';

    standings = addCutLine(season, standings);

    return (
        <TableContainer className={className} sx={{ overflow: 'hidden' }}>
            <Table
                size="small"
                aria-label="simple table"
                className="striped"
                sx={{ minWidth: 200, maxWidth: 1200, tableLayout: 'fixed' }}
            >
                <TableHead sx={{ bgcolor }}>
                    <TableRow>
                        <TableCell sx={{ color, width: '10px' }}>#</TableCell>
                        <TableCell sx={{ color }}>Player</TableCell>
                        <TableCell sx={{ color }} align="right" size="small" width="50px">
                            Points
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {standings.map((row) =>
                        row.cut ? (
                            <TableRow key="CUT">
                                <TableCell colSpan="3" className="cut-line">
                                    <span className="dash"></span>CUT<span className="dash"></span>
                                </TableCell>
                            </TableRow>
                        ) : (
                            <TableRow
                                key={row.player._id}
                                sx={{
                                    '&:last-child td, &:last-child th': {
                                        border: 0,
                                    },
                                    bgcolor: calcBg(row.player, user),
                                }}
                            >
                                <TableCell align="left" sx={{ width: '10px' }}>
                                    {row.position}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                    <PlayerAvatar player={row.player} />
                                    <PlayerLink player={row.player} />
                                </TableCell>
                                <TableCell align="right" size="small">
                                    {row.points} ({row.tournamentsPlayed})
                                </TableCell>
                            </TableRow>
                        )
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

function addCutLine(season, standings) {
    const newStandings = [];
    let lineAdded = false;

    if (standings && standings.length > 0) {
        for (const standing of standings) {
            if (standing.position > season.cut && !lineAdded) {
                lineAdded = true;
                newStandings.push({ cut: true });
            }
            newStandings.push(standing);
        }
    }

    return newStandings;
}
