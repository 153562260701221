import { useState, useEffect } from 'react';
import { useFetchWrapper } from '@apis/Utils';
import { Arena, Result } from '@apis/Types';

export function useArenas(enabled = true): { arenas: Arena[] } {
    const [arenas, setArenas] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        if (enabled) {
            get('arena').then((data) => {
                setArenas(data);
            });
        }
    }, [enabled]);

    return { arenas };
}

export function useArenaStats(id: string, season?: string): { arena: Arena; results: Result[] } {
    const [arena, setArena] = useState(null);
    const [results, setResults] = useState(null);
    const { get } = useFetchWrapper();

    useEffect(() => {
        const query = season ? `?season=${season}` : '';
        get(`arena/${id}/stats${query}`).then((data) => {
            setArena(data.arena);
            setResults(data.results);
        });
    }, [id, season]);

    return { arena, results };
}
